<template>
   <v-card class="chart_container pa-2" tile flat min-height="500px">
    <chart-header :title="title"
      :subtitle="subtitle"
      :widget_name="'quarterly-trends'"
      :total_result="total_records"
      :total_amount="total_amount"
      :search_query="plain_text_query"
      :chart_settings="chart_settings"
      :disable_show_data="true"
      :embed_object="embed_object"
      :embed_obj_changed="embed_obj_changed"
      @embed="embed_code"    
      @downloadImage="downloadImage"/>
    <chart-loader :reportLoading="reportLoading" />

    <v-row>
      <div class="d-inline pl-4 mr-5">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;">Metric</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              class="text-capitalize body-1"
                color="#E0E0E0"
                outlined
                v-bind="attrs"
                v-on="on"
                width="130"
                style="justify-content:left !important;"
              >
                <span style="color:#12253a;">{{metric.text}}</span>
                <v-icon color="#12253a" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, value) in metric_options"
                :key="value"
                @click="metric = item, updateChartData(), updateSettings()"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-inline mr-5">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;">Date Orientation</p>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                class="text-capitalize body-1"
                  color="#E0E0E0"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  width="160"
                  style="justify-content:left !important;"
                >
                  <span style="color:#12253a;">{{date_option.text}}</span>
                  <v-icon color="#12253a" right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, value) in date_options"
                  :key="value"
                  @click="date_option = item, updateChartData(), updateSettings()"
                >
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
        </div>
        <div class="d-inline">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;"># Entities / Categories</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              class="text-capitalize body-1"
                color="#E0E0E0"
                outlined
                v-bind="attrs"
                v-on="on"
                width="130"
                style="justify-content:left !important;"
              >
                <span style="color:#12253a;">{{num_groups}}</span>
                <v-icon color="#12253a" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in top_entities"
                :key="item"
                @click="num_groups = item, updateSettings(), refresh()"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
    </v-row>

      <v-row>
        <v-col cols="12">
          <quarterly-line-chart v-if="contracted_fy_by_qtr_aggs.length > 0"
            :data="chart_data"
            :series="chart_data"
            :chart_axes="chart_axes"
            :x_axis_data="chart_labels"
            :changed="changed"
            :download="download"
            :yAxisMetric="metric.value"
            :target_group="group.value"
            :embed="embed"
            @chart_object="chart_object"
            @showTenders="showTenders"
            :interval="interval.value"
            :id="id"
          />
          <v-card flat tile v-else-if="contracted_fy_by_qtr_aggs.length == 0 && reportLoading==false">
            <v-card-title>No Data</v-card-title>
          </v-card>
        </v-col>
      
      </v-row>
  </v-card>
</template>

<script>
import { histogramSearch, entitySearch } from "./APIManager.js";
import { silentURLUpdate, formattedCurrencyFull, formattedCurrency, govColor, getPercent } from "./Utils.js";
//import LineChart from "../components/LineChart.vue";
import QuarterlyLineChart from "./QuarterlyLineChart.vue";
import ChartHeader from "../components/ChartHeader";
import ChartLoader from "../components/ChartLoader";
//import ChartLegend from "./ChartLegend.vue";

export default {
  components: {
    //LineChart,
    QuarterlyLineChart,
    ChartHeader,
    ChartLoader
    //ChartLegend
    //Donut
  },
  props: {
    title: {
      type: String,
      default: "Quarterly Trends by Financial Year"
    },
    description: String
  },
  data() {
    return {
      chart_settings: [],
      aggGroup: ["contracted", "expiry"],
      outlined: true,
      defaultButton: 0,
      toggleButton: "rgb(255, 20, 99)",
      toggleText: "View By Start Date",
      toggleOn: false,
      legend: null,
      subtitle: "Quarter by quarter comparison by FY",
      showLegend: true,
      item_details: false,
      aggregated_data: [],
      //publisher_line_chart_data: [],
      series: {},
      line_chart_headers: [],
      chart_data: [],
      chart_labels: [],
      chart_axes: { x: "Transactions", y: "Year" },
      group_by_amount: true,
      target_entity: String,
      ready: false,
      changed: Number,
      date_options: [{text: "Contract Start", value:"contract_start"}, {text: "Expiry Date", value:"expiry_date"}],
      date_option: {text: "Contract Start", value:"contract_start"},
      currentAggType: "contracted",
      metric_name: "doc_count",
      sum_options: [{text: "Actual", value:"actual"}, {text: "Cumulative Active", value:"active"}, {text: "Cumulative Total", value:"cumulative"}],
      sum: {text: "Cumulative Total", value:"cumulative"},
      metric_options: [{text: "Amount", value:"total_amount"}, {text: "Volume", value:"doc_count"}],
      metric: {text: "Amount", value:"total_amount"},
      group_options: [{text: "Buyer", value:"publisher"},
                      {text: "Major Category", value:"major_category"},
                      {text: "Category", value:"category"},
                      {text: "Supplier", value:"supplier"},
                      {text: "Government", value:"government"},
                      {text: "Sector", value:"publisher_industry"}],
      group: {text: "Supplier", value:"supplier"},
      interval_options: [{text: "Quarter", value:"quarter"}, {text: "Year", value:"year"}, {text: "Fiscal Year", value:"fy"}],
      interval: {text: "Year", value:"year"},
      top_entities: [5, 10],
      num_groups: 5,
      total_records: Number,
      total_amount: Number,
      reportLoading: Boolean,
      plain_text_query:String,
      download: Number,
      legendData: [],
      active_contracts_by_yr_aggs: [],
      active_contracts_by_fy_aggs: [],
      date_aggs: [],
      expiry_aggs: [],
      published_aggs: [],
      contracting_activity: [],
      expiring_activity: [],
      entity_aggs: [],
      supplier_aggs:[],
      category_aggs: [],
      major_category_aggs: [],
      publisher_aggs: [],
      government_aggs: [],
      sector_aggs: [],
      quarterly_aggs:[],
      fy_aggs:[],
      keyword_aggs: [],
      keyword_aggs_count: [],
      buyer_cluster_aggs: [],
      supplier_cluster_aggs: [],
      buyer_cluster_aggs_count:[],
      supplier_cluster_aggs_count:[],
      quarterly_aggs_count:[],
      supplier_aggs_count:[],
      category_aggs_count: [],
      major_category_aggs_count: [],
      publisher_aggs_count: [],
      government_aggs_count: [],
      sector_aggs_count: [],
      publisher_activity: [],
      date_aggs_count: [],
      entity_type: "supplier",
      embed: Number,
      embed_object: Object,
      embed_obj_changed: Number,
      contracted_fy_by_qtr_aggs:[],
      expiry_fy_by_qtr_aggs:[],
      contracted_count_fy_by_qtr_aggs:[],
      expiry_count_fy_by_qtr_aggs:[]
      //loading: true
    };
  },
  watch: {
    $route(to, from) {
      this.refresh();
    },

    interval(){
      this.updateValues();
    },

    sum(){
      this.updateValues()
    }
  },

  computed: {

    id(){
      return (Math.random() + Date.now()) * Math.random();
    },

  },

  async mounted() {
    this.reportLoading = true;
    this.loadSettings();
    this.loadData(this.$route.query);
  },

  methods: {

    formattedCurrency,

    chart_object(obj){
      this.embed_object["title"] = this.title;
      this.embed_object["description"] = this.description;
      this.embed_object["chart_type"] = "line-chart";
      this.embed_object["payload"] = obj
      this.embed_object["id"] = (Math.random() + Date.now()) * Math.random();
      this.embed_obj_changed = (Math.random() + Date.now()) * Math.random();
    },

    embed_code(status){
      this.embed =
           (Math.random() + Date.now()) * Math.random();

    },

    generate_json(){

      let payload = {};
      payload["series"]=this.chart_data
      payload["chart_axes"]=this.chart_axes
      payload["x_axis_data"]=this.chart_labels
      payload["changed"]=this.changed
      payload["download"]=this.download
      payload["metric_type"]=this.metric.value
      payload["target_group"]=this.group.value
      payload["interval"]=this.interval.value

      ////////////////console.log(JSON.stringify(payload));

    },

    updateSettings(){

      this.chart_settings[0] = {field_name: "qrt_trends_date_orientation", value: this.date_option.value};
      this.chart_settings[1] = {field_name: "qrt_trends_num_groups", value: this.num_groups};
      this.chart_settings[2] = {field_name: "qrt_trends_metric_type", value: this.metric.value};

      silentURLUpdate(this.chart_settings);

      ////////////////console.log ("INTERVAL: ", JSON.stringify(this.interval));

      silentURLUpdate(this.chart_settings);
      
    },

    loadSettings(){
      var query = this.$route.query;
      if (query.hasOwnProperty("qrt_trends_date_orientation")){
        let index = this.date_options.findIndex(x => x.value === query.qrt_trends_date_orientation);
        delete this.date_option.text;
        delete this.date_option.value;
        this.date_option = this.date_options[index];
      }
      if (query.hasOwnProperty("qrt_trends_num_groups")){
        this.num_groups = query.qrt_trends_num_groups;
      }
      if (query.hasOwnProperty("qrt_trends_metric_type")){
        let index = this.metric_options.findIndex(x => x.value === query.qrt_trends_metric_type);
        delete this.metric.text;
        delete this.metric.value;
        this.metric = this.metric_options[index];
      }
    },

    showTenders(args){
      this.$emit("showTenders", args);
    },

    downloadImage(status){
      this.download =
          (Math.random() + this.chart_data.length) * Math.random();
    },

    async refresh(){
      this.reportLoading = true;
      await this.loadData(this.$route.query);
    },


    updateChartData(){
      this.reportLoading = true;
      if (this.date_option.value == "contract_start"){
        if (this.metric.value == "total_amount"){
          this.chart_data = this.contracted_fy_by_qtr_aggs;
        }
        else{
          this.chart_data = this.contracted_count_fy_by_qtr_aggs;
          //console.log(this.chart_data);
        }
      }
      else{
        if (this.metric.value == "total_amount"){
          this.chart_data = this.expiry_fy_by_qtr_aggs;
        }
        else{
          this.chart_data = this.expiry_count_fy_by_qtr_aggs;
        }
        
      }
      this.setChanged();
      this.reportLoading = false;
    },


    setChanged(){
      this.changed =
          (Math.random() + this.chart_data.length) * Math.random();
      //this.generate_json();
    },

    async loadData(qry) {

      var query = JSON.parse(JSON.stringify(qry));
      query["agg_size"] = this.num_groups;

      histogramSearch(query, 1)
        .then(data => {
          ////////////////console.log("histogramSearch ", data);
          this.total_records = data.hits.value;
          this.total_amount = data.aggregations.total_amount.value;
          this.plain_text_query = data.plain_text_query; 
          this.contracted_fy_by_qtr_aggs = data.aggregations.contracted_fy_by_qtr.buckets;
          this.expiry_fy_by_qtr_aggs = data.aggregations.expiry_fy_by_qtr.buckets;
          this.contracted_count_fy_by_qtr_aggs = data.aggregations.contracted_count_fy_by_qtr.buckets;
          this.expiry_count_fy_by_qtr_aggs = data.aggregations.expiry_count_fy_by_qtr.buckets;
          
        })
        .catch(error => {
          ////////////////console.log(error);
          //this.errored = true;
          //this.overlay = false;
        })
        .finally(() => {
          this.updateChartData()
        });
    },
  }
};
</script>

<style>
.chart_container {
    max-width: 100% !important;
    /* overflow: auto !important; */
    border: none !important;
    height: 100% !important;
    background-color: #fff !important;
}

.chart-legend {
  list-style-type: none;
  font-size: 9pt;
  font-family: Roboto;
  /*width: fit-content;
  margin: 0.5rem auto;*/
  margin-left: 0px;
  padding-left: 0px;
}
.chart-legend li {
  padding: 0.25rem;
}
.chart-legend .legend-item {
  height: 11;
  display: flex;
}
.chart-legend .legend-item .label {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0px;
  padding-left: 0px;
}
.chart-legend .legend-item span {
  cursor: pointer;
  margin-right: 0.75rem;
  margin-top: 0.2rem;
  border-radius: 0.75rem;
  width: 0.3rem;
  height: 0.75rem;
}

.chart-legend .legend-item span:hover {
  text-decoration: underline;
}
</style>
