<template>
  <div :id="id" class="reportLine"></div>
</template>

<script>
import * as echarts from "echarts";
import {downloadFile} from "./Utils";
import $ from 'jquery';
//import jsondata from "../temp/life-expectancy-table.json";

export default {

 props:{
     series: Array(),
     legend_data: Array(),
     x_axis_data: [],
     changed: Number,
     download: Number,
     metric_type: {
        type: String(),
        default: "total_amount"
      },
     target_group: String,
     interval: String,
     story: Boolean,
     embed: Number,
     chart_options: {},
     id: Number,
      chart_size: {
        type: String(),
        default: "large"
      },
      yAxisMetric: {
        type: String,
        required: true,
        default:"total_amount"
      },
      data: {
        type: Array,
        required: true
      },
 },
  data() {
    return {
      options: {},
      myChart: null,
      canvasCSS: String(),
      chartHeight: Number(),
      chartWidth: Number(),
      legend_padding: Number()
    };
  },

  computed: {
    
  },

  watch: {
    changed() {
      //this.myChart.dispose();
      this.chartOptions();
    },

    embed() {
      //////////////console.log("CALL SHARE CHART\n", this.myChart);
      
      this.saveChart();
    },

    download() {
      this.saveImage();
    }
  },

  mounted() {
      //nextTick will fix chart width at 100px issue
      this.$nextTick(()=>{
        this.chartOptions();
      });
  },

  methods: {

    saveChart(){
      var imgUrl = this.myChart.getDataURL();

      let data = {
        imgData: imgUrl, //this.myChart.getDataURL(),
        options: this.options
      };

      //console.log("QTRLINE CHART PAYLOAD: ", data);

      this.$emit("chart_object", data);

    },

    chartOptions() {

      if (this.myChart != null && this.myChart != "" && this.myChart != undefined) {
            this.myChart.dispose();
      }

      var chartDom = document.getElementById(this.id);
      this.myChart = echarts.init(chartDom);

      if (this.story){
        this.myChart.setOption(this.chart_options);
      }
      else{

        const chartData = this.processChartData();

        // Sorting the series based on FY name (assuming format "FYXX")
        const sortedFYs = Object.keys(chartData).sort((a, b) => {
          return parseInt(a.replace('FY', '')) - parseInt(b.replace('FY', ''));
        });

        var option =  {
          color: [
                    "#12253a",
                    "#ff1463",
                    "#d73e32",
                    "#2196f3",
                    "#004700",
                    "#2d9437",
                    "#004d9f",
                    "#007cd6",
                    "#d73e32",
                    "#de004b",
                    "#ff457c",
                    "#4a575d",
                    "#7b898f",
                    "#bb5900",
                    "#bb5900",
                    "#ffa72d",
                    "#4caf50",
                    "#2196f3",
                    "#fb8c00",
                    "#ff1463",
                    "#b71c1c",
                    "#ff937b",
                    "#95e8ff",
                    "#a2ff9e",
                    "#fffb82",
                    "#FFE70A",
                    "#SFS603",
                    "#7F7305",
                    "#BFAB07",
                    "#FF6905",
                    "#5405FF"
                  ],
          xAxis: {
            type: 'category',
            data: ['Q1', 'Q2', 'Q3', 'Q4']
          },
          yAxis: {
            type: 'value',
            scale: true,
            axisLabel: {
                showMinLabel: false,
                showMaxLabel: true,
                formatter : (s) => {
                    function convert (num){
                        if (num > 999 && num < 1000000) {
                            num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                        } else if (num >= 1000000 && num < 1000000000) {
                            num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                        } else if (num >= 1000000000) {
                            num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                        }

                        return num;
                    }

                    if (this.yAxisMetric == "total_amount"){
                      return '{valueStyle|' + '$'+ convert(s) + '}';
                    }else{
                      return '{valueStyle|' + s + '}';
                    }
                    
                },
                rich: {
                    valueStyle: {
                        // Make yearly text more standing out
                        color: '#000',
                        fontWeight: 'normal',
                        fontSize: 12
                    }
                }
            },
        },
          series: Object.keys(chartData).map(fy => ({
            name: fy,
            type: 'line',
            data: chartData[fy],
            smooth: true
          })),

          legend: {
              show: true,
              icon: "circle",
              orient: 'vertical',
              right: 10,
              top: 'middle',
              data: sortedFYs
              //type: "scroll",
              //right: 0,
              //top:'center',
              //bottom: 0
            },

            grid: {
              left: "1.5%",
              bottom: "20px",
              top: "20px",
              //right: 240,
              containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            formatter: (params) => {
              ////console.log(params);
              function convert (num){
                if (num > 999 && num < 1000000) {
                  num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                } else if (num >= 1000000 && num < 1000000000) {
                  num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                } else if (num >= 1000000000) {
                  num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                }
                return num;
              }

              function getColor(number){
                if (number.includes("-")) return "red";
                else if (number.includes("+")) return "green";
                else return "black";
              }

              var infocard = [];

              // Sort the params by seriesName (financial year)
              params.sort((a, b) => {
                  return parseInt(a.seriesName.replace('FY', '')) - parseInt(b.seriesName.replace('FY', ''));
              });

              for (var i in params){
                let val = params[i].data;

                if (this.yAxisMetric == "doc_count"){
                  val = val;
                }
                else{
                  val = "$" + convert(val);
                }

                //let iconStyle = '"background-color:'+params[i].data.color + 'flex-shrink: 0; width: 14px;height: 14px;border-radius: 50%;margin-right: 12px;display: inline; align-items: center;justify-content: center;fill: #fff;border: 2px solid #fff;box-shadow: 0 2px 4px rgb(0 0 0 / 25%);"';
                //iconStyle = iconStyle + 'background-color:'+params[i].data.color;
                var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:14px;height:14px;border: 2px solid #fff;box-shadow: 0 2px 4px rgb(0 0 0 / 25%);background-color:' + color + '"></span>';
                infocard[i] = [
                  '<tr><td style="text-align: left;">',
                    //'<div style="color: #12253a; font-size:12; font-family:Roboto; font-weight:normal;">',
                      ''+colorSpan(params[i].color)+'',
                      '<span style="color: #12253a; font-size:12; font-family:Roboto; font-weight:normal; padding-right:10px;">' + params[i].seriesName + '</span>',
                  '</td>',
                  '<td style="text-align: left;">',
                      '<span style="color: #12253a; font-size:12; font-family:Roboto; font-weight:bold; padding-right:10px;">' + val + '</span>',
                  '</td>',
                      //'<span style="color: #12253a; font-size:12; font-family:Roboto; font-weight:bold; float: right;"> (' + params[i].data.percent + ')</span></div>',
                ].join('');
                
              };

              //add header
              infocard.unshift('<table align="left"><thead><tr><th style="text-align: left;padding-right:10px;">' + params[i].name + '</th><th style="text-align: left;padding-right:10px;">Total</th></tr></thead>');
              infocard.push('</table>');

              return infocard.join('');
            }
          },
        };

        ////console.log(option);

        this.options = option;

        this.myChart.setOption(option);
      }
    },

    setStyle () {
      if (this.chart_size == "large"){
        this.chartHeight= 60;
        this.chartWidth=180;
        this.legend_padding = -30;
        this.canvasCSS = "reportPieLarge";
        
      }
      else{
        this.chartHeight= 40;
        this.chartWidth=120;
        this.legend_padding = -30;
        this.canvasCSS = "reportPieSmall";;
      }
            
    },

    saveImage(){

      var img = new Image();
      
      img.src = this.myChart.getDataURL({
          pixelRatio: 2,
          backgroundColor: '#fff'
      });

      downloadFile(img.src);

    },

    processChartData() {
      const result = {};

      this.data.forEach(fyData => {
        const fy = fyData.key;

        ['Q1', 'Q2', 'Q3', 'Q4'].forEach(q => {
          let quarter;
          if (fyData && fyData.quarter) {
            quarter = fyData.quarter.buckets.find(bucket => bucket.key === q);
          }

          if (!result[fy]) {
            result[fy] = [];
          }
          if (this.yAxisMetric == "total_amount"){
            result[fy].push(quarter ? quarter[this.yAxisMetric].value : 0);
          }
          else{
            result[fy].push(quarter ? quarter[this.yAxisMetric] : 0);
          }
          
        });
      });
      return result;
    },

    getMonthsInQuarter(qtr){
      switch (qtr) {
        case "1":
          return ["January", "February", "March"];
        case "2":
          return ["April", "May", "June"];
        case "3":
          return ["July", "August", "September"];
        case "4":
          return ["October", "November", "December"];
        
      }
    }
  }
}

</script>
<style>
.reportLine {
  margin-top: 0px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  width: 100% !important;
  height: 500px !important;
  /* padding: 0px, 0px, 0px, 0px !important; */
}

.storyLine {
  margin-top: 0px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  width: 1040px !important;
  height: 500px !important;
  /* padding: 0px, 0px, 0px, 0px !important; */
}
</style>