<template>
   <v-card class="chart_container pa-2" tile flat min-height="500px">
    <chart-header :title="title"
      :subtitle="subtitle"
      :widget_name="'cumulative-comparison'"
      :total_result="total_records"
      :total_amount="total_amount"
      :search_query="plain_text_query"
      :chart_settings="chart_settings"
      :disable_show_data="true"
      :embed_object="embed_object"
      :embed_obj_changed="embed_obj_changed"
      @embed="embed_code"    
      @downloadImage="downloadImage"/>
    <chart-loader :reportLoading="reportLoading" />

    <v-row>
        <div class="d-inline pl-4 mr-5">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;">Metric</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              class="text-capitalize body-1"
                color="#E0E0E0"
                outlined
                v-bind="attrs"
                v-on="on"
                width="130"
                style="justify-content:left !important;"
              >
                <span style="color:#12253a;">{{metric.text}}</span>
                <v-icon color="#12253a" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, value) in metric_options"
                :key="value"
                @click="metric = item, updateSettings(), chartConfig(), setChanged(), generate_json()"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-inline mr-5">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;">Entity Type</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              class="text-capitalize body-1"
                color="#E0E0E0"
                outlined
                v-bind="attrs"
                v-on="on"
                width="175"
                style="justify-content:left !important;"
              >
                <span style="color:#12253a;">{{group.text}}</span>
                <v-icon color="#12253a" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, value) in group_options"
                :key="value"
                @click="group = item, updateSettings(), set_entity_aggs(), chartConfig()"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-inline mr-5">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;">Interval</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              class="text-capitalize body-1"
                color="#E0E0E0"
                outlined
                v-bind="attrs"
                v-on="on"
                width="160"
                style="justify-content:left !important;"
              >
                <span style="color:#12253a;">{{interval.text}}</span>
                <v-icon color="#12253a" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
               <v-list-item
                v-for="(item, value) in interval_options"
                :key="value"
                @click="interval = item, updateSettings(), chartConfig(), setChanged()"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-inline mr-5">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;">Progression</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              class="text-capitalize body-1"
                color="#E0E0E0"
                outlined
                v-bind="attrs"
                v-on="on"
                width="200"
                style="justify-content:left !important;"
              >
                <span style="color:#12253a;">{{sum.text}}</span>
                <v-icon color="#12253a" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
               <v-list-item
                v-for="(item, value) in sum_options"
                :key="value"
                @click="sum = item, updateSettings(), chartConfig(), setChanged()"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-inline">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;"># Entities / Categories</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              class="text-capitalize body-1"
                color="#E0E0E0"
                outlined
                v-bind="attrs"
                v-on="on"
                width="130"
                style="justify-content:left !important;"
              >
                <span style="color:#12253a;">{{num_groups}}</span>
                <v-icon color="#12253a" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in top_entities"
                :key="item"
                @click="num_groups = item, updateSettings(), refresh()"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        
    </v-row>

      <v-row>
        <v-col cols="12">
          <e-line-chart v-if="chart_data.data.length > 0"
            :series="chart_data"
            :chart_axes="chart_axes"
            :x_axis_data="chart_labels"
            :changed="changed"
            :download="download"
            :metric_type="metric.value"
            :target_group="group.value"
            :embed="embed"
            @chart_object="chart_object"
            @showTenders="showTenders"
            :interval="interval.value"
            :id="id"
          />
          <v-card flat tile v-else-if="chart_data.data.length == 0 && reportLoading==false">
            <v-card-title>No Data</v-card-title>
          </v-card>
        </v-col>
      
      </v-row>
  </v-card>
</template>

<script>
import {
  prepMultiLineData,
  prepEchartsSeries,
  prepCumulativeSpend,
  prepActiveContracts,
  getXLabels
} from "./PrepLineChart.js";
import { histogramSearch, entitySearch } from "./APIManager.js";
import { bus } from "../main.js";
import { silentURLUpdate, formattedCurrencyFull, formattedCurrency, govColor, getPercent } from "./Utils.js";
//import LineChart from "../components/LineChart.vue";
import ELineChart from "./ELineChart.vue";
import ChartHeader from "../components/ChartHeader";
import ChartLoader from "../components/ChartLoader";
//import ChartLegend from "./ChartLegend.vue";

export default {
  components: {
    //LineChart,
    ELineChart,
    ChartHeader,
    ChartLoader
    //ChartLegend
    //Donut
  },
  props: {
    title: {
      type: String,
      default: "Participant Awards Over Time"
    },
    description: String
  },
  data() {
    return {
      chart_settings: [],
      aggGroup: ["contracted", "expiry"],
      outlined: true,
      defaultButton: 0,
      toggleButton: "rgb(255, 20, 99)",
      toggleText: "View By Start Date",
      toggleOn: false,
      legend: null,
      subtitle: "Cumulative or actual award totals over time",
      showLegend: true,
      item_details: false,
      aggregated_data: [],
      //publisher_line_chart_data: [],
      series: {},
      line_chart_headers: [],
      chart_data: { data: [], activity: [] },
      chart_labels: [],
      chart_axes: { x: "Transactions", y: "Year" },
      group_by_amount: true,
      target_entity: String,
      ready: false,
      changed: Number,
      currentAggType: "contracted",
      metric_name: "doc_count",
      sum_options: [{text: "Actual", value:"actual"}, {text: "Cumulative Active", value:"active"}, {text: "Cumulative Total", value:"cumulative"}],
      sum: {text: "Cumulative Total", value:"cumulative"},
      metric_options: [{text: "Amount", value:"total_amount"}, {text: "Volume", value:"doc_count"}],
      metric: {text: "Amount", value:"total_amount"},
      group_options: [{text: "Buyer", value:"publisher"},
                      {text: "Major Category", value:"major_category"},
                      {text: "Category", value:"category"},
                      {text: "Supplier", value:"supplier"},
                      {text: "Government", value:"government"},
                      {text: "Sector", value:"publisher_industry"}],
      group: {text: "Supplier", value:"supplier"},
      interval_options: [{text: "Quarter", value:"quarter"}, {text: "Year", value:"year"}, {text: "Fiscal Year", value:"fy"}],
      interval: {text: "Year", value:"year"},
      top_entities: [5, 10],
      num_groups: 5,
      total_records: Number,
      total_amount: Number,
      reportLoading: Boolean,
      plain_text_query:String,
      download: Number,
      legendData: [],
      active_contracts_by_yr_aggs: [],
      active_contracts_by_fy_aggs: [],
      date_aggs: [],
      expiry_aggs: [],
      published_aggs: [],
      contracting_activity: [],
      expiring_activity: [],
      entity_aggs: [],
      supplier_aggs:[],
      category_aggs: [],
      major_category_aggs: [],
      publisher_aggs: [],
      government_aggs: [],
      sector_aggs: [],
      quarterly_aggs:[],
      fy_aggs:[],
      keyword_aggs: [],
      keyword_aggs_count: [],
      buyer_cluster_aggs: [],
      supplier_cluster_aggs: [],
      buyer_cluster_aggs_count:[],
      supplier_cluster_aggs_count:[],
      quarterly_aggs_count:[],
      supplier_aggs_count:[],
      category_aggs_count: [],
      major_category_aggs_count: [],
      publisher_aggs_count: [],
      government_aggs_count: [],
      sector_aggs_count: [],
      publisher_activity: [],
      date_aggs_count: [],
      entity_type: "supplier",
      embed: Number,
      embed_object: Object,
      embed_obj_changed: Number
      //loading: true
    };
  },
  watch: {
    $route(to, from) {
      this.refresh();
    },

    interval(){
      this.updateValues();
    },

    sum(){
      this.updateValues()
    }
  },

  computed: {

    id(){
      return (Math.random() + Date.now()) * Math.random();
    },

    /* sum_options(){
      if (interval.value == "quarter"){
        return [{text: "Actual", value:"actual"}, {text: "Cumulative Total", value:"cumulative"}];
      }
      else{
        return [{text: "Actual", value:"actual"}, {text: "Cumulative Active", value:"active"}, {text: "Cumulative Total", value:"cumulative"}];
      }
    },

    interval_options(){
      if (sum.value == "active"){
        return [{text: "Year", value:"year"}, {text: "Fiscal Year", value:"fy"}];
      }
      else{
        return [{text: "Quarter", value:"quarter"}, {text: "Year", value:"year"}, {text: "Fiscal Year", value:"fy"}];
      }
    } */
  },

  async mounted() {
    this.reportLoading = true;
    this.loadSettings();
    this.loadData(this.$route.query);
  },

  methods: {

    formattedCurrency,

    chart_object(obj){
      this.embed_object["title"] = this.title;
      this.embed_object["description"] = this.description;
      this.embed_object["chart_type"] = "line";
      this.embed_object["payload"] = obj
      this.embed_object["id"] = (Math.random() + Date.now()) * Math.random();
      this.embed_obj_changed = (Math.random() + Date.now()) * Math.random();
    },

    embed_code(status){
      this.embed =
           (Math.random() + Date.now()) * Math.random();

    },

    generate_json(){

      let payload = {};
      payload["series"]=this.chart_data
      payload["chart_axes"]=this.chart_axes
      payload["x_axis_data"]=this.chart_labels
      payload["changed"]=this.changed
      payload["download"]=this.download
      payload["metric_type"]=this.metric.value
      payload["target_group"]=this.group.value
      payload["interval"]=this.interval.value

      //////////////console.log(JSON.stringify(payload));

    },

    updateValues(){
      if (this.interval.value == "quarter"){
        this.sum_options = this.sum_options.filter(option => option.value !== "active");
      }
      else{
        this.sum_options = this.sum_options.filter(option => option.value !== "active")
        this.sum_options.push({ text: "Cumulative Active", value: "active" });
      }

      if (this.sum.value == "active"){
        this.interval_options= this.interval_options.filter(option => option.value !== "quarter");
      }
      else{
        this.interval_options= this.interval_options.filter(option => option.value !== "quarter");
        this.interval_options.push({text: "Quarter", value:"quarter"});
      }
    },

    updateSettings(){

      this.chart_settings[0] = {field_name: "comparison_metric_value", value: this.metric.value};
      this.chart_settings[1] = {field_name: "comparison_sum_value", value: this.sum.value};
      this.chart_settings[2] = {field_name: "comparison_group_value", value: this.group.value};
      this.chart_settings[3] = {field_name: "comparison_interval_value", value: this.interval.value};
      this.chart_settings[4] = {field_name: "comparison_num_groups", value: this.num_groups};

      //////////////console.log ("INTERVAL: ", JSON.stringify(this.interval));

      silentURLUpdate(this.chart_settings);
      
    },

    loadSettings(){
      var query = this.$route.query;
      if (query.hasOwnProperty("comparison_group_value") && query.comparison_group_value == "search_terms"){
        delete this.group.text;
        delete this.group.value;
        this.group = {text: "Keyword", value:"search_terms"};

      }
      else if (query.hasOwnProperty("comparison_group_value") && query.comparison_group_value == "supplier_clusters"){
        delete this.group.text;
        delete this.group.value;
        this.group = {text: "Supplier Cluster", value:"supplier_clusters"};

      }
      else if (query.hasOwnProperty("comparison_group_value") && query.comparison_group_value == "buyer_clusters"){
        delete this.group.text;
        delete this.group.value;
        this.group = {text: "Buyer Cluster", value:"buyer_clusters"};

      }
      else if (query.hasOwnProperty("comparison_group_value")){
        let index = this.group_options.findIndex(x => x.value === query.comparison_group_value);
        delete this.group.text;
        delete this.group.value;
        this.group = this.group_options[index];
      }
      
      if (query.hasOwnProperty("comparison_metric_value")){
        let index = this.metric_options.findIndex(x => x.value === query.comparison_metric_value);
        delete this.metric.text;
        delete this.metric.value;
        this.metric = this.metric_options[index];
      }

      if (query.hasOwnProperty("comparison_interval_value")){
        let index = this.interval_options.findIndex(x => x.value === query.comparison_interval_value);
        delete this.interval.text;
        delete this.interval.value;
        this.interval = this.interval_options[index];
      }
      if (query.hasOwnProperty("comparison_sum_value")){
        let index = this.sum_options.findIndex(x => x.value === query.comparison_sum_value);
        delete this.sum.text;
        delete this.sum.value;
        this.sum = this.sum_options[index];
      }
      if (query.hasOwnProperty("comparison_num_groups")){
        this.num_groups = query.comparison_num_groups;
      }
    },

    showTenders(args){
      this.$emit("showTenders", args);
    },

    set_entity_aggs(){
      this.entity_aggs = [];

      if (this.group.value == "major_category"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.major_category_aggs_count;
        else
          this.entity_aggs = this.major_category_aggs;
      }
      else if (this.group.value == "category"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.category_aggs_count;
        else
          this.entity_aggs = this.category_aggs;
      }else if (this.group.value == "publisher"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.publisher_aggs_count;
        else
          this.entity_aggs = this.publisher_aggs;
      }else if (this.group.value == "government"){
        if (this.metric.value == "doc_count"){
          this.entity_aggs = this.government_aggs_count;
        }
        else
          this.entity_aggs = this.government_aggs;
      }
      else if (this.group.value == "publisher_industry"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.sector_aggs_count;
        else
          this.entity_aggs = this.sector_aggs;
      }
      else if (this.group.value == "search_terms"){
          this.entity_aggs = this.keyword_aggs;
      }
      else if (this.group.value == "buyer_clusters"){
          this.entity_aggs = this.buyer_cluster_aggs;
      }
      else if (this.group.value == "supplier_clusters"){
          this.entity_aggs = this.supplier_cluster_aggs;
      }
      else {
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.supplier_aggs_count;
        else 
          this.entity_aggs = this.supplier_aggs;
      }
    },

    downloadImage(status){
      this.download =
          (Math.random() + this.chart_data.length) * Math.random();

    },

    async refresh(){
      this.reportLoading = true;
      //this.chart_data.data = [];
      await this.loadData(this.$route.query);

      var query = this.$route.query;

      if (query.hasOwnProperty("comparison_group_value") && query.comparison_group_value == "search_terms"){
        let index = this.group_options.findIndex(x => x.value === "search_terms");
        this.group_options.splice(index, 1);
      }

      if (query.hasOwnProperty("comparison_group_value") && query.comparison_group_value == "buyer_clusters"){
        let index = this.group_options.findIndex(x => x.value === "buyer_clusters");
        this.group_options.splice(index, 1);
      }

      if (query.hasOwnProperty("comparison_group_value") && query.comparison_group_value == "supplier_clusters"){
        let index = this.group_options.findIndex(x => x.value === "supplier_clusters");
        this.group_options.splice(index, 1);
      }
    },

    setChanged(){
      this.changed =
          (Math.random() + this.chart_data.data.length) * Math.random();
      //this.generate_json();
    },

    async loadData(qry) {

      var query = JSON.parse(JSON.stringify(qry));
      query["agg_size"] = this.num_groups;

      entitySearch(query, 1)
        .then(data => {
          this.supplier_aggs = data.aggregations.supplier.buckets;
          this.category_aggs = data.aggregations.category.buckets;
          this.major_category_aggs = data.aggregations.major_category.buckets;
          this.publisher_aggs = data.aggregations.publisher.buckets;
          this.government_aggs = data.aggregations.government.buckets;
          this.sector_aggs = data.aggregations.publisher_industry.buckets;

          if (data.aggregations.search_terms){
            this.keyword_aggs = data.aggregations.search_terms.buckets;

            //if it doesnt already exist in the dropdown
            if (!this.group_options.find(o => o.value === 'search_terms')){
              this.group_options.push({text: "Keyword", value:"search_terms"})
            }
            
          }

          if (data.aggregations.buyer_clusters){
            this.buyer_cluster_aggs = data.aggregations.buyer_clusters.buckets;
            if (!this.group_options.find(o => o.value === 'buyer_clusters')){
              this.group_options.push({text: "Buyer Cluster", value:"buyer_clusters"})
            }
            
          }

          if (data.aggregations.supplier_clusters){
            this.supplier_cluster_aggs = data.aggregations.supplier_clusters.buckets;
            if (!this.group_options.find(o => o.value === 'supplier_clusters')){
              this.group_options.push({text: "Supplier Cluster", value:"supplier_clusters"})
            }
            
          }

          //count
          this.supplier_aggs_count = data.aggregations.supplier_by_count.buckets;
          this.category_aggs_count = data.aggregations.category_by_count.buckets;
          this.major_category_aggs_count = data.aggregations.major_category_by_count.buckets;
          this.publisher_aggs_count = data.aggregations.publisher_by_count.buckets;
          this.government_aggs_count = data.aggregations.government_by_count.buckets;
          this.sector_aggs_count = data.aggregations.publisher_industry_by_count.buckets;
          
        })
        .catch(error => {
          //////////////console.log(error);
          //this.errored = true;
          //this.overlay = false;
        })
        .finally(() => {

          histogramSearch(query, 1)
            .then(data => {
              //////////////console.log("histogramSearch ", data);
              this.total_records = data.hits.value;
              this.total_amount = data.aggregations.total_amount.value;
              this.plain_text_query = data.plain_text_query;
              this.expiring_activity = data.aggregations.expiring_activity.buckets;
              this.date_aggs = data.aggregations.contracted.buckets;
              this.active_contracts_by_yr_aggs = data.aggregations.active_contracts_by_year.buckets;
              this.active_contracts_by_fy_aggs = data.aggregations.active_contracts_by_fy.buckets;
              this.fy_aggs = data.aggregations.contracted_by_fy.buckets;
              ////console.log("ASSIGNED VALUE TO FY AGGS: ", this.fy_aggs);
              this.quarterly_aggs = data.aggregations.contracted_by_quarter.buckets;
              this.quarterly_aggs_count = data.aggregations.contract_count_by_quarter.buckets;
              this.date_aggs_count = data.aggregations.contracting_activity_annual.buckets;
              this.expiry_aggs = data.aggregations.expiring.buckets;
              this.published_aggs = data.aggregations.published.buckets;
              //this.entity_aggs = data.aggregations.supplier.buckets;
              this.publisher_activity =
                data.aggregations.transactions_by_month.buckets;
              this.contracting_activity =
                data.aggregations.contracting_activity.buckets;
              //this.setGroups();
              //this.setColors();
              
            })
            .catch(error => {
              //////////////console.log(error);
              //this.errored = true;
              //this.overlay = false;
            })
            .finally(() => {
              this.set_entity_aggs();
              this.chartConfig();
              this.reportLoading = false;
              this.setChanged();
            })
        });
    },

    get_targets(bucket_data, type) {
      let targets = [];
      let target_count = 0;

      //for each year get the targets
      for (var y = 0; y < bucket_data.length; y++) {
        let data_for_year = bucket_data[y][type].buckets;

        for (var t = 0; t < data_for_year.length; t++) {
          targets[target_count] = data_for_year[t].key;
          //////////////console.log("entity: " + data_for_year[t].key);
          target_count++;
        }
      }
      return targets;
    },

    chartConfig() {

      let data = [];

      let target_aggs = [];

      //////////////console.log(this.quarterly_aggs);
      
      if (this.metric.value == "total_amount") {
        if (this.interval.value == "quarter"){
          target_aggs = this.quarterly_aggs;
        }
        else if((this.interval.value == "fy" || this.interval.value == "year") && this.sum.value == "active"){
          ////console.log(this.interval.value);
          if (this.interval.value == "fy"){
            ////console.log("we're active");
            target_aggs = this.active_contracts_by_fy_aggs;
          }
          else{
            ////console.log("in year");
            target_aggs = this.active_contracts_by_yr_aggs;
          }
        }
        else if (this.interval.value == "fy"){
          target_aggs = this.fy_aggs;
          target_aggs.sort((a, b) => (a.key > b.key) ? 1 : -1)
        }
        else{
          target_aggs = this.date_aggs;
        }
      }else{
        if (this.interval.value == "quarter"){
          target_aggs = this.quarterly_aggs_count;
        }
        else if((this.interval.value == "fy" || this.interval.value == "year") && this.sum.value == "active"){
          ////console.log(this.interval.value);
          if (this.interval.value == "fy"){
            ////console.log("we're active");
            target_aggs = this.active_contracts_by_fy_aggs;
          }
          else{
            ////console.log("in year");
            target_aggs = this.active_contracts_by_yr_aggs;
          }
        }
        else if (this.interval.value == "fy"){
          target_aggs = this.fy_aggs;
          target_aggs.sort((a, b) => (a.key > b.key) ? 1 : -1)
        }
        else{
          target_aggs = this.date_aggs_count;
        } 
      }

      //let contracted_aggs = this.date_aggs;

      let targets = this.entity_aggs;
      

      let colors = [
        "#12253a",
        "#ff1463",
        "#d73e32",
        "#2196f3",
        "#004700",
        "#006000",
        "#00791e",
        "#2d9437",
        "#004d9f",
        "#0064ba",
        "#007cd6",
        "#d73e32",
        "#de004b",
        "#ff457c",
        "#4a575d",
        "#7b898f",
        "#bb5900",
        "#bb5900",
        "#ffa72d",
        "#4caf50",
        "#2196f3",
        "#fb8c00",
        "#ff1463",
        "#b71c1c",
        "#ff937b",
        "#95e8ff",
        "#a2ff9e",
        "#fffb82",
        "#FFE70A",
        "#SFS603",
        "#7F7305",
        "#BFAB07",
        "#FF6905",
        "#5405FF"
      ];

      if (this.sum.value == "actual"){
        ////console.log("target aggs: ", target_aggs);
        for (var t in targets) {
          let color = colors.shift();
          let target_data = prepEchartsSeries(
            target_aggs,
            this.group.value,
            this.metric.value,
            targets[t].key,
            targets[t].key,
            color
          );
          data[t] = target_data;
        }
        ////console.log("ACTUAL: ", data);
      }
      else if (this.sum.value == "cumulative") {
        for (var t in targets) {
          let color = colors.shift();
          
          let target_data = prepCumulativeSpend(
            target_aggs,
            this.group.value,
            this.metric.value,
            targets[t].key,
            targets[t].key,
            color
          );
          data[t] = target_data;
        }
      }
      else{
        for (var t in targets) {
          let color = colors.shift();
          let target_data = prepActiveContracts(
            target_aggs,
            this.group.value,
            this.metric.value,
            targets[t].key,
            targets[t].key,
            color
          );
          data[t] = target_data;
        }
        ////console.log("ACTIVE: ", data);

      }

      this.chart_labels = getXLabels(target_aggs);
      this.chart_data["data"] = data;

      this.setChanged();
    }
  }
};
</script>

<style>
.chart_container {
    max-width: 100% !important;
    /* overflow: auto !important; */
    border: none !important;
    height: 100% !important;
    background-color: #fff !important;
}

.chart-legend {
  list-style-type: none;
  font-size: 9pt;
  font-family: Roboto;
  /*width: fit-content;
  margin: 0.5rem auto;*/
  margin-left: 0px;
  padding-left: 0px;
}
.chart-legend li {
  padding: 0.25rem;
}
.chart-legend .legend-item {
  height: 11;
  display: flex;
}
.chart-legend .legend-item .label {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0px;
  padding-left: 0px;
}
.chart-legend .legend-item span {
  cursor: pointer;
  margin-right: 0.75rem;
  margin-top: 0.2rem;
  border-radius: 0.75rem;
  width: 0.3rem;
  height: 0.75rem;
}

.chart-legend .legend-item span:hover {
  text-decoration: underline;
}
</style>
