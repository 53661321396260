<template>
  <div class="pa-0 ma-0" style="background-color:#f5f5f5" fluid>
    <v-content>
      <v-container class="px-0 pt-3 mt-0 mr-2" fluid>
        <v-row class="ma-0 pt-0" fluid>
          <v-col cols="12" md="12" class="pt-0 mb-0 pb-0">
        <v-row class="mx-auto px-0 py-0" style="background-color:#f5f5f5">
         <v-col cols="12" class="px-0 pb-0 pt-2">

            <v-card tile>
            <v-card-title style="color:#12253a;">
                
                <span v-if="panel"
                class="d-inline-block text-truncate text-capitalize"
                style="max-width:600px; min-width:150px;"
                >
                  <v-card-subtitle color="secondary" class="pa-0 font-weight-light"><span v-if="panel.agency">{{panel.agency}} | </span><span>{{ panel.son_id }}</span></v-card-subtitle>
                  <span>{{ panel.title }}</span>
                
                </span>
                <div class="flex-grow-1 desktop-nav"></div>
                <span>
                  <v-avatar
                    :color="govAvatarColor(panel.gov_type)"
                    size="36"
                    class="mr-3 pl-0 ml-0"
                  >
                    <span class="white--text caption">{{ panel.gov_type }}</span>
                  </v-avatar>
                </span>
                <span>
                <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                    <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        large
                        icon
                        color="#4EB888"
                        target="_blank"
                        light
                        style="text-decoration: none !important;"
                        :to="{ name: 'search', query: { son_id: panel.son_id, government: panel.gov_type } }"
                    >
                        <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-btn>
                    </template>
                    <span>View all {{ panel.title }} notices</span>
                </v-tooltip>
                </span>
                <span>
                <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                    <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        large
                        icon
                        color="#4EB888"
                        target="_blank"
                        light
                        style="text-decoration: none !important;"
                        :to="{ name: 'search', query: { son_id: panel.son_id, government: panel.gov_type, viewIndex:2 } }"
                    >
                        <v-icon>mdi-graphql</v-icon>
                    </v-btn>
                    </template>
                    <span>View who's buying from who</span>
                </v-tooltip>
                </span>
                <span>
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    large
                    icon
                    color="secondary"
                    light
                    :href="panel.son_url"
                    target="_blank"
                    style="text-decoration: none !important;"
                    :disabled="disableIcon(panel.son_url)"
                  >
                    <v-icon>mdi-web</v-icon>
                  </v-btn>
                </template>
                <span>Go to source</span>
              </v-tooltip>
            </span>
                
                </v-card-title
            >
            <v-card-subtitle>
            <div
                v-if="panel.son_start"
                style="color:#12253a;"
                class="font-weight-strong"
              >
              <b>PERIOD</b> {{ getMonthYear(panel.son_start) }}<span v-if="panel.son_start"></span>
              <b v-if="panel.son_end"> - </b>{{ getMonthYear(panel.son_end) }}
            </div>
            <!-- <div
                v-if="panel.multi_agency"
                style="color:#12253a;"
                class="font-weight-strong"
              >
              <b>MULTI-AGENCY</b> {{ panel.multi_agency }}
            </div> -->
              <div v-if="panel.description" class="font-weight-normal pt-2 pb-2">
                  {{ panel.description }}
              </div>

              <span v-if="panel.primary_category_name">
                <router-link target="_blank"
                            :to="{ name: 'search', query: { category: panel.primary_category_name } }"
                          >
                    <v-chip
                        class="my-1 ml-0 mr-2"
                        color="secondary"
                        label
                        text-color="secondary"
                        small
                        outlined
                  >
                    <v-icon small left>
                      mdi-label
                    </v-icon>
                    {{panel.primary_category_name}}
                  </v-chip>
                </router-link>
            </span>
            <span v-if="panel.secondary_category_name">
                <router-link target="_blank"
                            :to="{ name: 'search', query: { category: panel.secondary_category_name } }"
                          >
                    <v-chip
                        class="my-1 ml-0 mr-2"
                        color="secondary"
                        label
                        text-color="secondary"
                        small
                        outlined
                  >
                    <v-icon small left>
                      mdi-label
                    </v-icon>
                    {{panel.secondary_category_name}}
                  </v-chip>
                </router-link>
            </span>
            </v-card-subtitle>

            
            <v-row class="ma-0 pa-0">
                <v-divider></v-divider>
                <v-col cols="12">
                  <search-analysis :query="search"></search-analysis>
                </v-col>
            </v-row>

            </v-card>
         </v-col>
        </v-row>
        
        <v-tabs class="pt-4 pb-1" color="rgb(18, 37, 58)" show-arrows v-if="no_members">
          <v-tab>
            Supplier Leaderboard
          </v-tab>
          <v-tab>
            Buyer Leaderboard
          </v-tab>
          <v-tab>
            Participation
          </v-tab>
          <v-tab>
            Recent Notices
          </v-tab>
          <v-tab @click="inactiveSuppliers()">
            Inactive Suppliers
          </v-tab>
          <v-tab-item></v-tab-item>
          <v-tab-item></v-tab-item>
        </v-tabs>
        <v-tabs v-else class="pt-4 pb-1" color="rgb(18, 37, 58)" show-arrows>
          <v-tabs-slider color="rgb(255, 20, 99)"></v-tabs-slider>
          <v-tab>
            Supplier Leaderboard
          </v-tab>
          <v-tab>
            Buyer Leaderboard
          </v-tab>
          <v-tab>
            Participation
          </v-tab>
          <v-tab>
              Recent Notices
          </v-tab>
          <v-tab @change="inactiveSuppliers()">
            Inactive Suppliers
          </v-tab>
          <v-tab-item>
              <v-row style="background-color:#f5f5f5">
              <v-col cols="12" class="pt-3">
                  <panel-members :startIndex="2" :son_id="panel.son_id" :entity_type="'supplier'"></panel-members>
              </v-col>
              </v-row>
          </v-tab-item>
          <v-tab-item>
              <v-row style="background-color:#f5f5f5">
              <v-col cols="12" class="pt-3">
                  <panel-members :startIndex="0" :son_id="panel.son_id" :entity_type="'publisher'"></panel-members>
              </v-col>
              </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row class="ma-0 pa-0" v-if="panel.son_id">
                <v-col cols="12">
                  <v-card tile flat class="mx-auto pa-2" height="320px">
                    <v-row>
                    <v-col
                      cols="6"
                      class="pb-0 d-flex child-flex"
                    >
                      <panel-participation-container
                          :gov_type="panel.gov_type"
                          :son_id="panel.son_id"
                          :title="'Supplier Participation (%) by Volume'"
                        />
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0 d-flex child-flex"
                    >
                      <panel-participation-container
                          :son_id="panel.son_id"
                          :gov_type="panel.gov_type"
                          :percentageType="'percentage_value'"
                          :title="'Supplier Participation (%) by Value'"
                        />
                    </v-col>

                    </v-row>
                </v-card>
                <v-card tile flat class="mx-auto px-2 py-2" height="320px">
                  <v-row>
                    <v-col
                      cols="6"
                      class="pb-0 d-flex child-flex"
                    >
                      <panel-participation-container
                          :gov_type="panel.gov_type"
                          :son_id="panel.son_id"
                          :title="'Buyer Participation (%) by Volume'"
                          :entity_type="'publisher'"
                        />
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0 d-flex child-flex"
                    >
                      <panel-participation-container
                          :son_id="panel.son_id"
                          :gov_type="panel.gov_type"
                          :percentageType="'percentage_value'"
                          :title="'Buyer Participation (%) by Value'"
                          :entity_type="'publisher'"
                        />
                    </v-col>

                    </v-row>
                </v-card>
                </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
              <v-row style="background-color:#f5f5f5">
              <v-col cols="12" class="pt-3">
                  <v-skeleton-loader
                  v-if="overlay"
                  type="table-tbody"
                  ></v-skeleton-loader>
                  <results-table
                      v-else
                      class="pb-3 pt-0 pl-0 pr-0"
                      :hit_info="hit_info"
                      :tenders="tenders"
                      :hide_pagination="true"
                      />
                  <v-overlay absolute="true" :value="overlay">
                  <v-progress-circular
                      indeterminate
                      size="64"
                  ></v-progress-circular>
                  </v-overlay>
              </v-col>
              </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-skeleton-loader
                v-if="inactive_overlay"
                type="table-tbody"
                ></v-skeleton-loader>
            <v-data-table
              v-else
              :headers="inactive_supp_headers"
              :items="inactive_suppliers"
              :items-per-page="100"
              :footer-props="{
                      'items-per-page-options': [100]
                  }"
            >
              <template v-slot:item.name="{ item }">
                  <router-link style="font-weight-normal caption" :to="'/supplier/'+item.id">{{item.name}}</router-link>
              </template>
              <template v-slot:item.website="{ item }">
                {{ item.website || 'N/A' }}
              </template>
              <template v-slot:item.address="{ item }">
                {{ item.address || 'N/A' }}
              </template>
              <template v-slot:item.country="{ item }">
                {{ item.country || 'N/A' }}
              </template>
              <template v-slot:item.deed_start="{ item }">
              {{ formatDate(item.deed_start) }}
              </template>
              <template v-slot:item.deed_end="{ item }">
              {{ formatDate(item.deed_end) }}
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
     </v-row>
    </v-container>
   </v-content>
 </div>
</template>

<script>
import {
  executeSearch,
  getPanel,
  getPanelSuppliers
} from "../components/APIManager.js";
import SearchAnalysis from "../components/SearchAnalysis.vue";
import { growth_format, numFormatter, formattedCurrency, govAvatarColor, getMonthYear } from "../components/Utils.js";
import ResultsTable from "../components/ResultsTable.vue";
import PanelMembers from "../components/PanelMembers.vue";
import PanelParticipationContainer from "../components/PanelParticipationContainer.vue";

export default {
  components: {
    PanelMembers,
    ResultsTable,
    SearchAnalysis,
    PanelParticipationContainer
  },

  data() {
    return {
      inactive_supp_headers: [
        { text: 'Name', value: 'name' },
        { text: 'Website', value: 'website' },
        { text: 'Address', value: 'address' },
        { text: 'Country', value: 'country' },
        { text: 'Postcode', value: 'postcode' },
        { text: 'State', value: 'state' },
        { text: 'ABN', value: 'company_number' },
        { text: 'Deed Start', value: 'deed_start' },
        { text: 'Deed End', value: 'deed_end' }
      ],
      inactive_suppliers: [],
      inactive_overlay: false,
      entity: "",
      current_search: Object,
      entity_id: String,
      cluster_id: Number,
      supplier_id: Number,
      entity_ids: Array,
      entity_type: String,
      target_entity: String,
      query: String,
      expiring_activity: [],
      expiry_aggs: [],
      hit_info: {},
      tenders: [""],
      overlay: false,
      tabs: null,
      absolute: true,
      entity_panel: -9,
      entity_panels: [],
      panel_clicked: false,
      //current_index: 0,
      index: Number, //use the exp panel index as key so that we can cache the data
      profiles: [],
      mounted: false,
      total_amt_metric: "total_amount",
      search: Object,
      category_aggs: [],
      date_aggs: [],
      supplier_aggs: [],
      contracting_activity: [],
      publish_activity:[],
      annual_spend: [],
      avg_contract: Number,
      loading_stats: false,
      //industry: [],
      clusterParam: String,
      //panelType: "",
      panel: Object,
      loaded: Number,
      no_members: false
    };
  },

  computed: {
    isDataLoaded() {
        const isLoaded = Object.keys(this.entity).map(key => this.entity[key].length !== 0)
        return this.entity && isLoaded.length !== 0
    }
  },

  created() {
    
  },

  mounted() {
      this.search = JSON.parse(JSON.stringify(this.$route.query));
      this.loadPanel();
      this.overlay = true;
      this.loadRecentAwards();
      this.overlay = false;
  },
    

  watch: {
    $route(to, from) {

    },

  },
  computed:{
    panelType(){
      
      if (this.panel.is_panel === "Yes"){
        return " Government Panel " + "("+this.panel.gov_type+")"
      }
      else if (this.panel.is_panel === "No") {
        return "Government Non-Panel Arrangement " + "("+this.panel.gov_type+")"
      }
      else {
        return "";
      }
    }
  },

  methods: {

    growth_format,
    numFormatter,
    formattedCurrency,
    govAvatarColor,
    getMonthYear,
    getPanelSuppliers,

    formatDate(date) {
      if (date != "None") {
        return new Date(date).toLocaleDateString();
      }
      else{
        return 'N/A';
      }
      
    },

    reset_aggs(){
      this.expiry_aggs = [];
      this.expiring_activity = [];
      this.date_aggs = [];
      this.contracting_activity = [];
      this.publish_activity = [];
      this.annual_spend = [];
      this.category_aggs = [];
      this.supplier_aggs = [];
    },

    async loadPanel(){

      this.panel = await getPanel(this.$route.query.son_id, this.$route.query.government);

      if(this.panel == null) {
        this.panel = {
          title: this.$route.query.son_id,
          gov_type: this.$route.query.government
        };
      }

    },


    async loadRecentAwards() {
      let results = {};

      var query = JSON.parse(JSON.stringify(this.$route.query));
      query["sort_field"]= "expiry_date";
      query["sort_order"]= "desc";
      query["size"]= 10;

      //let labels = [];
      results = await executeSearch(query);

      ////////////console.log("TABLE RESULTS: ", results)

      this.tenders = results.data;
      this.hit_info = results.hits;
      this.overlay = false;
    },

    async inactiveSuppliers() {
      this.inactive_overlay = true;
      getPanelSuppliers(this.$route.query.son_id, this.$route.query.government, "inactive", 1)
        .then(results => {
          this.inactive_suppliers = results.data;
        })
        .finally(() => (this.inactive_overlay = false));
    },

  
    disableIcon(url) {
      if (!url) {
        return true;
      } else {
        return false;
      }
    },

    toggle() {
      this.watching = !this.watching;
      this.is_saved();
    },

    is_saved() {
      ////////////console.log("NOTIFY STATUS: " + this.current_search.notify)
      return this.watching;
    }
  }
};
</script>

<style>
.chart {
  border-color: #424242 !important;
}

.headingUnderline {
  border-color: aqua !important;
}
</style>
