<template>
  <div class="fill-height pa-0 ma-0" style="background-color:#f5f5f5" fluid>
    <v-dialog
          v-model="overlay"
          hide-overlay
          persistent
          width="300"
          lazy          
        >
          <v-card
            color="secondary"
            dark
          >
            <v-card-text>
              Fetching data...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
    </v-dialog>
    <v-content v-if="item">
      <v-row class="mx-auto pb-0 pt-3">
        <v-col cols="12" class="pa-0 mt-0">
          <v-tabs class="pl-3 pr-2" color="rgb(18, 37, 58)" show-arrows v-model="active_tab">
            <v-tabs-slider color="rgb(255, 20, 99)"></v-tabs-slider>
            <v-tab>
              Info
            </v-tab>
            <v-tab v-if="item && item['timeline']">
              Timeline
            </v-tab>
            <v-tab v-if="item && item['related_notices']">
              Related Notices
            </v-tab>
            <v-tab @click="buildSimilarMatchQuery()">
              Similar Notices
            </v-tab>

            <!-- <v-tab>
              Possible Bidders
            </v-tab> -->

            <v-tab-item>
              <v-row class="mx-auto pa-0" style="background-color:#f5f5f5"
                >
                <v-col cols="12" class="px-0 pb-1 pt-5"
                  ><v-card tile class="pb-0 pt-0 mb-0">
                    <v-card-title style="color:secondary;"
                      ><v-chip
                        small
                        :color="dispositionColor(item.disposition)"
                        label
                        text-color="#FFFFF0"
                        class="mr-3"
                      >
                        <v-icon small left>mdi-label</v-icon>
                        {{ item.disposition }}
                      </v-chip>

                      <v-chip
                        v-if="item.contract_end"
                        small
                        :color="expiryColor(daysToExpiry(item.contract_end))"
                        label
                        text-color="#FFFFF0"
                        class="ml-0 mr-3"
                      >
                        <v-icon small left>mdi-clock</v-icon>
                        {{ expiryText(daysToExpiry(item.contract_end)) }}
                      </v-chip>

                      <v-chip
                        v-else-if="item.closing_date"
                        small
                        :color="expiryColor(daysToExpiry(item.closing_date))"
                        label
                        text-color="#FFFFF0"
                        class="ml-0 mr-3"
                      >
                        <v-icon small left>mdi-clock</v-icon>
                        {{ expiryText(daysToExpiry(item.closing_date)) }}
                      </v-chip>

                      <span>
                        {{ item.title }}
                      </span>
                      <div class="flex-grow-1 desktop-nav"></div>
                      <span>
                        <v-tooltip
                          top
                          dark
                          color="#000000"
                          style="opacity: 0.7;"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              large
                              icon
                             
                              light
                              :href="item.url"
                              target="_blank"
                              :disabled="disableIcon(item.url)"
                              style="text-decoration: none !important;"
                            >
                              <v-icon>mdi-web</v-icon>
                            </v-btn>
                          </template>
                          <span>Go to Source</span>
                        </v-tooltip>
                        <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              small
                              color="secondary"
                              light
                              icon
                              @click="bookmarkItem(item)"
                              v-on="on"
                            >
                              <v-icon v-if="item.bookmarked">mdi-bookmark</v-icon>
                              <v-icon v-else>mdi-bookmark-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Add / Remove Prospect</span>
                      </v-tooltip>
                      </span></v-card-title
                    >

                    <v-card-text>{{ item.description }}</v-card-text>
                  <v-card-title v-if="item.category" class="pt-0">
                      <v-list-item two-line class="pl-0">
                        <v-list-item-content>
                          <div
                            class="subtitle-1 font-weight-bold card-data"
                          >
                            Category
                          </div>
                          <v-list-item-title v-if="item['parent_category'] && item.parent_category.name != 'Yes'" class="pt-1"
                            ><span class="body-2 font-weight-normal"
                              >{{ item.parent_category.code }} :
                              {{ item.parent_category.name }}</span
                            ></v-list-item-title
                          >
                          <v-list-item
                            class="category-indent"
                            style="min-height:30px !important;"
                          >
                          <router-link
                              text
                              style="text-decoration: none; !important;"
                              :to="{
                                name: 'category',
                                params: { id: item.category_id }
                              }"
                            >
                            <span class="subtitle-1 font-weight-medium"
                              >{{ item.category_code }} :
                              {{ item.category }}</span
                            ><span><v-btn icon x-small color='secondary' :to="{ name: 'category', params: { id: item.category_id }}"><v-icon>mdi-open-in-new</v-icon></v-btn></span>
                          </router-link>
                          </v-list-item>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item v-if="item.is_consultancy_services == 'Yes'" one-line class="pt-0 pl-0">
                        <v-list-item-content>
                          <v-list-item
                            class="consult-indent"
                            style="min-height:30px !important;"
                          >
                              <span class="subtitle-1 font-weight-normal">Classified as Consultancy</span >
                              <!-- <span v-if="item.is_consultancy_services" class="subtitle-1 font-weight-medium">Consultancy: {{ item.is_consultancy_services }}</span > -->
                              <!-- <span v-else class="subtitle-1 font-weight-medium">Consultancy: Undisclosed</span > -->
                          </v-list-item>
                        </v-list-item-content>
                      </v-list-item>
                      
                    </v-card-title>
                  </v-card>
                  
                </v-col></v-row
              >
              <v-row class="mx-auto pa-0"
                ><v-col cols="12" class="px-0 pb-1 pt-0"
                  ><v-card tile class="pb-0 mb-0">
                    <v-card-title style="color:#12253a;" class="pb-4">
              
                    <span
                        class="d-inline-block text-truncate"
                        style="max-width:800px; min-width:150px;"
                    >
                        <v-card-subtitle color="secondary" class="pa-0">NOTICE REF | {{item.recid}}</v-card-subtitle>
                        <v-card-subtitle v-if="item.son_ref" class="pa-0 font-weight-normal">STANDING OFFER / PANEL REF | 
                          <router-link
                              text
                              style="text-decoration: none; !important;"
                              :to="{
                                name: 'panel',
                                query: { son_id: item.son_ref, government: item.government }
                              }"
                            >{{item.son_ref}}
                          </router-link>
                        </v-card-subtitle>
                        <!-- <span>
                            Key Data
                        </span> -->
                    </span>
                    </v-card-title>
                <v-row class="mx-auto pa-0"
                  >
                  <v-col cols="12" md="5" class="ma-0 pa-0">
                    <v-card flat>
                    <v-card-text class="pt-2 pb-0 font-weight-normal" >Dates</v-card-text>
                    <v-divider class="mx-3"></v-divider>
                    <v-row class="ma-0 pa-0">
                    <v-col v-if="isValidDate(item.published_date)" cols="4" class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                            <v-card-title
                            class="font-weight-normal body-2"
                            style="color:#12253a;"
                            >Published</v-card-title
                            >
                            <v-card-subtitle>
                            <div class="font-weight-medium subtitle-1" style="color:#12253a;">
                                {{formatDateLong(item.published_date)}}
                            </div>
                            </v-card-subtitle>
                        </v-card>
                        </v-col>
                    
                        <v-col v-if="item.contract_start" cols="4" class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                            <v-card-title
                            class="font-weight-normal body-2"
                            style="color:#12253a;"
                            >Term Start</v-card-title
                            >
                            <v-card-subtitle>
                            <div class="font-weight-medium subtitle-1" style="color:#12253a;">
                                {{ formatDateLong(item.contract_start) }}
                            </div>
                            </v-card-subtitle>
                        </v-card>
                        </v-col>

                        <v-col cols="4"  class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                            <v-card-title
                            class="font-weight-normal body-2"
                            style="color:#12253a;"
                            >Expiry</v-card-title
                            >
                            <v-card-subtitle>
                            <div v-if="item.contract_end" class="font-weight-medium subtitle-1" style="color:#12253a;">
                                {{ formatDateLong(item.contract_end) }}
                            </div>
                            <div v-else-if="item.closing_date" class="font-weight-medium subtitle-1" style="color:#12253a;">
                                {{ formatDateLong(item.closing_date) }}
                            </div>
                            <div v-if="item.contract_end && daysToExpiry(item.contract_end) >= 0" class="font-weight-normal body-2" :style="'color:'+ expiryColor(daysToExpiry(item.contract_end))+';'">
                              {{daysToExpiry(item.contract_end)}} days left
                            </div>
                            <div v-else-if="item.contract_end && daysToExpiry(item.contract_end) < 0" class="font-weight-normal body-2" :style="'color:'+ expiryColor(daysToExpiry(item.contract_end))+';'">
                              {{daysToExpiry(item.contract_end)}} days
                            </div>
                            <div v-else-if="item.closing_date && daysToExpiry(item.closing_date) >= 0" class="font-weight-normal body-2" :style="'color:'+ expiryColor(daysToExpiry(item.closing_date))+';'">
                              {{daysToExpiry(item.closing_date)}} days left
                            </div>
                            <div v-else-if="item.closing_date && daysToExpiry(item.closing_date) < 0" class="font-weight-normal body-2" :style="'color:'+ expiryColor(daysToExpiry(item.closing_date))+';'">
                              {{daysToExpiry(item.closing_date)}} days
                            </div>
                            </v-card-subtitle>
                        </v-card>
                        </v-col>
                    </v-row>
                    </v-card>
                  </v-col>
                  <v-col v-if="item['contact']" cols="12" md="2" class="ma-0 pa-0">
                    <v-card flat>
                    <v-card-text class="pt-2 pb-0 font-weight-normal" >Contact Information</v-card-text>
                    <v-divider class="mx-3"></v-divider>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12" class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                            <v-card-title
                            class="font-weight-normal body-2"
                            style="color:#12253a;"
                            >Name</v-card-title
                            >
                            <v-card-subtitle>
                            <div class="font-weight-medium subtitle-1" style="color:#12253a;">
                                <router-link
                                      :to="{
                                        name: 'search',
                                        query: {
                                          contact: entityName(item.contact.name)
                                        }
                                      }"
                                      >{{
                                        entityName(item.contact.name)
                                      }}</router-link
                                    >
                            </div>
                            <div v-if="item.contact.email" class="font-weight-normal body-2">
                              {{ entityEmail(item.contact.email) }}
                            </div>
                            </v-card-subtitle>
                        </v-card>
                      </v-col>
                    </v-row>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="2" class="ma-0 pa-0">
                    <v-card flat>
                    <v-card-text class="pt-2 pb-0 font-weight-normal" >Value</v-card-text>
                    <v-divider class="mx-3"></v-divider>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12" class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                            <v-card-title
                            class="font-weight-normal body-2"
                            style="color:#12253a;"
                            >Award Amount</v-card-title
                            >
                            <v-card-subtitle>
                            <div class="font-weight-medium subtitle-1" style="color:#12253a;">
                                <span v-if="item.value > 0">{{
                                    formattedCurrency(item.value)
                                  }}</span>
                                  <span v-else><v-icon>mdi-minus</v-icon></span>
                            </div>
                            </v-card-subtitle>
                        </v-card>
                      </v-col>
                    </v-row>
                    </v-card>
                  </v-col>
                  <v-col v-if="item.disposition == 'Contract Notice'" cols="12" md="3" class="ma-0 pa-0">
                    <v-card flat>
                    <v-card-text class="pt-2 pb-0 font-weight-normal" >Transparency
                      <!-- <v-tooltip left max-width="400" class="pa-0 ma-0">
                        <template v-slot:activator="{ on }">
                          <v-btn class="pt-0" x-small v-on="on" icon target="_blank" href="https://docs.tendertrace.com/guide/user-documentation/transparency-integrity-and-risk">
                                <v-icon small color="primary">mdi-information-outline</v-icon>
                              </v-btn>
                        </template>
                        <v-card flat tile class="pa-0 ma-0">
                          <span>
                          The Transparency Score is calculated based on global best practice public sector procurement integrity and transparency factors. In Australia, procurement transparency standards and obligations vary between jurisdictions. Where possible, these rules have been incorporated into the calculations.
                          </span
                              >
                              <br />
                              Click the <v-icon small color="white">mdi-information-outline</v-icon> icon for more information.
                          <v-divider class="mt-2 mb-2" style="border-color:white;" v-if="missing_data.length > 0"></v-divider>
                        <v-card width="100%">
                          <transparency-spider
                            :data="{ 'payload': {checks: item.transparency_checks}, 'name': 'Transparency' }"
                          />
                        </v-card>
                        </v-card>
                      </v-tooltip> -->
                      <!-- <v-btn class="pt-0" x-small v-on="on" icon @click="showTransparencyBreakdown = !showTransparencyBreakdown">
                                <v-icon small color="primary">mdi-information-outline</v-icon>
                              </v-btn> -->
                    </v-card-text>
                    <v-divider class="mx-3"></v-divider>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="6" class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0" max-width="200">
                            <v-card-title
                            class="font-weight-normal body-2"
                            style="color:#12253a;"
                            >Score
                            <v-btn class="pt-0" x-small v-on="on" icon @click="showTransparencyBreakdown = !showTransparencyBreakdown">
                                <v-icon small color="primary">mdi-information-outline</v-icon>
                              </v-btn>
                            </v-card-title
                            >
                            <v-card-subtitle>
                            <!-- <div class="font-weight-medium subtitle-1">
                                <span :style="'color:'+transparencyColor()+';'">{{transparency}}%</span>
                            </div> -->
                            <v-progress-linear
                              :value="transparency"
                              :color="transparencyColor()"
                              height="20"
                              
                            >
                              <template v-slot:default="{ value }">
                                <strong class="font-weight-normal caption">{{ Math.ceil(value) }}%</strong>
                              </template>
                            </v-progress-linear>
                            </v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col cols="6" class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0" max-width="200">
                            <v-card-title
                            class="font-weight-normal body-2"
                            style="color:#12253a;"
                            >Procurement Method
                            </v-card-title
                            >
                            <v-card-subtitle>
                              <div class="font-weight-medium subtitle-1" style="color:#12253a;">
                                <span v-if="item.procurement_method">{{
                                    cleanString(item.procurement_method)
                                  }}</span>
                                  <span v-else><v-icon>mdi-minus</v-icon></span>
                            </div>
                            </v-card-subtitle>
                        </v-card>
                      </v-col>
                    </v-row>
                    </v-card>
                  </v-col>
                </v-row>
                </v-card>
                </v-col>
              </v-row>
              
              <v-row v-if="buyer_loaded" class="mx-auto px-0 py-0" style="background-color:#f5f5f5">
                <v-col cols="12" class="px-0 pb-0 pt-3">
                  <entity-profile-header :entity="buyer_profile"
                                         :loading_stats="overlay"
                                         :category_id="item.category_id"
                                         :all_time_header="all_time_header"
                                         :awards_last_year_header="awards_last_year_header"
                                         :expiry_next_year_header="expiry_next_year_header"/>
                </v-col>
              </v-row>
              <v-row v-if="supplier_loaded == true" class="mx-auto px-0 pb-3 pt-1" style="background-color:#f5f5f5">
                <v-col cols="12" class="px-0 pb-3 pt-0">
                  <supplier-profile-header :entity="supplier_profile.profile"
                                         :loaded="supplier_loaded"
                                         :publisher_id="item.publisher_id"
                                         :all_time_header="'Awards from this Buyer - All Time'"
                                         :awards_last_year_header="'Awards from this Buyer - Last 12 Months'"
                                         :expiry_next_year_header="'Expiring with this Buyer - Next 12 Months'"/>
                </v-col>
              </v-row>

            </v-tab-item>
            <v-tab-item v-if="item && item['timeline']">
              <v-row class="mx-auto pa-0" style="background-color:#f5f5f5"
                ><v-col cols="12" class="px-0 pb-1 pt-5">
                  <timeline-card :timeline="item['timeline']"></timeline-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item v-if="item && item['related_notices']">
              <v-row style="background-color:#f5f5f5">
                <v-col cols="12" class="pt-0">
                  <v-skeleton-loader
                    v-if="overlay"
                    type="table-tbody"
                  ></v-skeleton-loader>
                  <results-table
                    v-else
                    @change-page="changePage"
                    class="pb-3 pt-0 pl-0 pr-0"
                    :hit_info="{
                      value: 1,
                      relation: 'eq',
                      total_pages: 1
                    }"
                    :tenders="item.related_notices"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row style="background-color:#f5f5f5"
                ><v-col cols="5" class="pb-3 pt-6 mb-0">
                  <quick-filter :current_buyer_flag="true" />
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-skeleton-loader
                    v-if="overlay"
                    type="table-tbody"
                  ></v-skeleton-loader>
                  <results-table
                    v-else
                    class="pb-3 pt-0 pl-1 pr-0"
                    :hit_info="hit_info"
                    :tenders="tenders"
                    :page_num="page_num"
                    :json_query="similar_notices_query"
                    @change-page="changePage"
                    @updateSort="updateSort"
                    @typeFilter="typeFilter"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
      <v-dialog v-model="showListManager" max-width="500px">
      <list-manager
        :items="user_lists"
        :selected="list_selections"
        :targetItem="selectedBookmark"
        :change="bookmark_changed"
        @hide-list-manager="showListManager=false"
        @bookmarked="updateBookmarks">

      </list-manager>
    </v-dialog> 
    <v-dialog v-model="showTransparencyBreakdown" max-width="1400px">
      <v-card flat tile class="mx-auto py-3 d-flex align-center" style="height: 60vh;">
        <v-row class="align-center" style="height: inherit;">
          <v-col cols="12" md="5" class="d-flex align-center">
            <v-card-text>
              <v-card-title>What is the Transparency Score?</v-card-title>
              <v-card-text>The Transparency Score is calculated based on global best practice public sector procurement integrity and transparency factors. In Australia, procurement transparency standards and obligations vary between jurisdictions. Where possible, these rules have been incorporated into the calculations. Visit the <a target="_blank" href="https://docs.tendertrace.com/guide/user-documentation/transparency-integrity-and-risk">user guide</a> for more information.
              </v-card-text>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="7">
            <v-card flat tile width="100%">
              <transparency-spider
                :data="{ 'payload': {checks: item.transparency_checks}, 'name': 'Transparency Scoring Breakdown' }"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-card>

    </v-dialog>
    </v-content>
    <router-view></router-view>
  </div>
</template>

<script>
import { bus } from "../main.js";
import { getInstance } from "../auth/index.js";
import {
  executeOpenSearch,
  getUserLists,
  addToList,
  fetchProfileByCounterparty,
  fetchSupplierByCounterparty,
  fetchProfile
} from "../components/APIManager.js";
import moment from "moment";
import axios from "axios";
import ResultsTable from "../components/ResultsTable.vue";
import TimelineCard from "../components/TimelineCard.vue";
import TransparencySpider from "../components/TransparencySpider.vue";
import EntityProfileHeader from "../components/entities/EntityProfileHeader.vue";
import SupplierProfileHeader from "../components/entities/SupplierProfileHeader.vue";
import QuickFilter from "../components/QuickFilter.vue";
import ListManager from "../components/ListManager.vue";

export default {
  components: {
    TimelineCard,
    ResultsTable,
    QuickFilter,
    ListManager,
    EntityProfileHeader,
    SupplierProfileHeader,
    TransparencySpider
  },

  data() {
    return {
      showTransparencyBreakdown: false,
      selectedBookmark: {},
      showListManager: false,
      bookmark_changed: 0,
      list_selections: [],
      active_tab: 0,
      percentComplete: Number,
      user_lists: [],
      added_tender: "",
      selectedItem: null,
      selectedTender: null,
      list_search: null,
      selectList: false,
      item: null,
      selected_avatar: null,
      show: null,
      hit_info: {},
      tenders: [""],
      results_start: 0,
      results_end: 0,
      query: {},
      page_num: 1,
      overlay: true,
      sort: {sort_field: "expiry_date", sort_order: "desc"},
      disposition: ["Contract Notice", "Request", "Grant", "Grant Opportunity"],
      similar_notices_query: {},
      missing_data: [],
      transparency: 0,
      buyer_profile: {},
      supplier_profile: {},
      supplier_loaded: false,
      buyer_loaded: false,
      all_time_header: 'Awards in this Category - All Time',
      awards_last_year_header: 'Awards in this Category - Last 12 Months',
      expiry_next_year_header: 'Expiring in this Category - Next 12 Months'

    };
  },

  async created() {
    await this.load_tender();

    bus.$on("update-filter", additional_filters => {
      ////////console.log(`Oh, that's nice. It's gotten ${item_details} clicks! :)`);

      for (var [key, value] of Object.entries(additional_filters)) {
        ////////console.log("ADDITIONAL FILTERS: " + `${key}: ${value}`);
        this.query[key] = value;
      }

      this.page_num = 1;
      this.loadSimilarNotices();
    });
  },

  mounted(){

  },

  watch: {
    $route(to, from) {
      this.load_tender();
      this.active_tab= 0;
    }
  },

  methods: {

    cleanString(str) {
      return str.replace(/^\s+|\s+$/g, "");
    },

    bookmarkItem(targetItem){
      ////////console.log("USER LISTS: ", this.user_lists);

      this.list_selections = [];

      for (var i in this.user_lists){
          let list = this.user_lists[i].search_query.tender_id;
          
          if (list.includes(parseInt(targetItem.id))){
              this.list_selections.push(this.user_lists[i]);
          }

      }
      
      this.selectedBookmark = targetItem;
      this.showListManager=true;
      //this.bookmark_changed = this.bookmark_changed + Math.round(Math.random() * 1000);
    },

    setBookmarks(data){

      this.user_lists = data;

      for (var i in this.user_lists){
        this.user_lists[i].search_query = JSON.parse(this.user_lists[i].search_query);
      }

      for (var l in this.user_lists){

        if (this.user_lists[l].search_query.tender_id.includes(parseInt(this.item.id))){
          this.item["bookmarked"] = true;
          break;
        }
        else{
          this.item["bookmarked"] = false;
        }

      }
      
    },

    updateBookmarks(bookmark_data){
      ////////console.log("BOOKMARK DATA: ", bookmark_data);

      var lists = [];
      var bms = bookmark_data.selectedLists;

      for (var i in bms){
        lists = lists.concat(JSON.parse(bms[i].id));
      }

      addToList(parseInt(bookmark_data.bookmarkedItemID), lists)
          .then(data => {

            this.setBookmarks(data);
          
            bus.$emit("refresh_lists", true);
            
          })
          .catch(error => {
            var dialog = {};
            dialog.msg =
              "We encountered an issue saving the selected notice. Please try again.";
            dialog.title = "Attempted save failed.";
            dialog.state = true;
          });
    },

    changePage(number){
      this.page_num = number;
      this.loadSimilarNotices();
    },

    updateSort(sortQuery){

      ////////console.log("ARGS: ", sortQuery);
      this.page_num = 1;
      this.overlay = true;

      this.sort_field = sortQuery.sort_field.value;
      this.sort_order = sortQuery.sort_order.value;
      
      //this.args.push(sortQuery)
      ////////console.log("ARGS: ", params);
      this.buildSimilarMatchQuery();
    },

    typeFilter(types){
      ////////console.log("ARGS: ", sortQuery);
      this.page_num = 1;
      this.overlay = true;

      this.disposition = types.disposition;

      this.buildSimilarMatchQuery();
    },

    getPercentComplete(startDate, endDate) {
      startDate = new Date(startDate);
      var now = new Date();
      var expDate = new Date(endDate);
      var totalDays = startDate.getTime() - expDate.getTime();
      totalDays = Math.round(totalDays / (1000 * 3600 * 24));

      var timeComplete = startDate.getTime() - now.getTime();
      var daysFromStart = Math.round(timeComplete / (1000 * 3600 * 24));

      var pc = (daysFromStart / totalDays) * 100;
      ////////console.log("PERCENT COMPLETE = " + pc + "%");
      //pc = (1531 / 1826) * 100;
      this.percentComplete = Math.ceil(pc);

      return this.daysToExpiry(endDate);
    },

    isValidDate(dte){

      if (!dte){
        return false
      }
      var thedate = new Date(dte);

      ////////console.log("FULL YEAR: ", thedate.getFullYear());

      if (thedate.getFullYear() == 1900){
        return false;
      }
      else{
        return true;
      }
    },

    daysToExpiry(dte) {
      var now = new Date();
      var expDate = new Date(dte);
      var timediff = expDate.getTime() - now.getTime();

      return Math.round(timediff / (1000 * 3600 * 24));
    },

    entityName(name) {
      name = name.replace("Agency", "");
      name = name.replace("Contact Name:", "");
      name = name.replace("Office", "");
      name = name.replace("Phone", "");
      name = name.replace(":", "");
      name = name.replace("(", "");
      name = name.replace(")", "");
      name = name.replace("()", "");
      name = name.replace("Ph", "");
      name = name.replace(/[0-9]/g, "");
      name = name.replace("+", "");

      name = name.replace(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi, "");
      return name;
    },

    entityEmail(mail) {
      mail = mail.replace("Email: ", "");
      return mail;
    },

    entityPhone(phone) {
      phone = phone.replace("Phone: ", "");
      return phone;
    },


    annualised_spend(startDte, endDte, amount) {
      var start = moment(new Date(startDte));
      var end = moment(new Date(endDte));

      var num_years = end.diff(start, "years", true);

      if (num_years <= 0 || !endDte) {
        num_years = 1;
      }

      return Math.round(amount / num_years);
    },

    async load_tender() {
      const instance = getInstance();
      const accessToken = await instance.getTokenSilently();

      this.overlay = true;
      var endpoint =
        process.env.VUE_APP_API_STANDARD_SEARCH + "/" + this.$route.params.id;

      ////////console.log(endpoint);

      axios.get(endpoint,{
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }).then(response => {
        
        this.item = response.data.data;
        this.selected_avatar = response.data.data.government;
        this.getLists();
        
        this.transparency = this.item.transparency_score;
        ////////console.log(this.item);

        this.fetchBuyerProfile();

        if (this.item.hasOwnProperty("supplier_id") && this.item.supplier_id){
          this.fetchSupplierProfile();
        }

      });

    },

    async fetchBuyerProfile(){
      if (this.item.hasOwnProperty("category") && this.item.category){
        if (this.item.category_parent_code)
          this.buyer_profile = await fetchProfileByCounterparty(this.item.publisher_id, this.item.category_id, "category")
        else
          this.buyer_profile = await fetchProfileByCounterparty(this.item.publisher_id, this.item.category_id, "parent_category")
        this.buyer_loaded = true;
      }
      else{
        this.all_time_header = 'Awards - All Time';
        this.awards_last_year_header = 'Awards - Last 12 Months';
        this.expiry_next_year_header = 'Expiring - Next 12 Months';
        this.buyer_profile = await fetchProfile(this.item.publisher_id);
        this.buyer_loaded = true;
      }

      this.overlay = false;
    },

    async fetchSupplierProfile(){
      this.supplier_profile = await fetchSupplierByCounterparty(this.item.supplier_id, this.item.publisher_id, "publisher");
      this.supplier_loaded = true;
      ////////console.log("SUPPLIER PROFILE: ", this.supplier_profile);
    },

    buildSimilarMatchQuery() {
      this.query = {};

      this.query["publisher_id"] = this.item.publisher_id;
      this.query["category_code"] = this.item.category_code;
      this.query["category_id"] = this.item.category_id;
      this.query["category"] = this.item.category;
      //this.query["title"] = this.item.title;
      this.query["id"] = this.item.id;
      this.query["supplier_id"] = this.item.supplier_id;
      this.query["supplier_company_number"] = this.item.supplier_company_number;
      this.query["recid"] = this.item.recid;
      this.query["sort_field"] = this.sort_field;
      this.query["sort_order"] = this.sort_order;
      this.query["disposition"] = this.disposition;
      ////////console.log("RUNNING QUERY: ", this.query);

      //this.query = this.item;

      this.loadSimilarNotices();
    },

    async loadSimilarNotices() {
      this.overlay = true;
      let results = {};

      //let labels = [];
      results = await executeOpenSearch(
        JSON.stringify(this.query),
        this.page_num
      );

      ////////console.log("TABLE RESULTS: ", results)

      this.tenders = results.data;
      this.hit_info = results.hits;
      this.similar_notices_query = results.json_query;
      this.overlay = false;
      
    },

    formatDate(date_value) {
      return moment(date_value).format("MMM YYYY");
    },

    transparencyColor() {
      
      switch (true){
        case (this.transparency >= 90):
          return "#4eb888";
        case (this.transparency  >= 70):
          ////////console.log("IGREATER THN 70");
          return "#DAD870";
        case (this.transparency  >= 50):
          return "#FF9636";
        case (this.transparency  < 50):
          return "#FF5C4D";
      }
    },

    transparencyScore(item) {

      var total = 100;
      var score = 0;
      
      if (item.published_date && this.isValidDate(item.published_date)){
        score += 20;
      }
      else{
        this.missing_data.unshift("Date Published");
      }
      if (item.category){
        score += 10;
      }
      else{
        this.missing_data.unshift("Category");
      }
      if (item.description && item.description.length > 100){
        score += 10;
      }
      else{
        this.missing_data.unshift("Description less than 100 characters");
      }
      if (item.disposition == "Contract Notice" && item.contract_start && this.isValidDate(item.contract_start)){
        score += 20;

        if (item.published_date && this.isValidDate(item.published_date) && this.daysBetween(item.published_date, item.contract_start) < 90){
          score += 20;
        }
        else{
          this.missing_data.unshift("Over 90 days between contract start and date published");
        }
      }
      else{
        this.missing_data.unshift("Contract Start Date");
      }

      if (item.disposition == "Contract Notice" && item.contract_end && this.isValidDate(item.contract_end)){
        score += 20;
      }
      else{
        this.missing_data.unshift("Contract End Date");
      }

      ////////console.log("MISSGING: ", this.missing_data);

      this.transparency = Math.round((score / total) * 100);

    },

    daysBetween(dte1, dte2) {
      var start = new Date(dte1);
      var end = new Date(dte2);
      var timediff = start.getTime() - end.getTime();

      return Math.round(timediff / (1000 * 3600 * 24));
    },

    /* getLists(tender_id) {
      bus.$emit("get-lists", tender_id);
    }, */

    formatDateLong(date_value) {
      return moment(date_value).format("DD MMM YYYY");
    },
    formattedCurrency: function(amount) {
      ////////console.log("AMOUNT: " + amount);
      if (!isNaN(amount)) {
        return (
          "$" +
          Math.round(amount)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      } //else {
      //return null;
      //}
    },

    dispositionColor(dis) {
      var color = "grey";
      switch (dis) {
        case "Request":
          color = "#4EB888";
          break;
        case "Grant Opportunity":
          color = "#4EB888";
          break;
        case "Contract Notice":
          color = "#FF1463";
          break;
        case "Grant":
          color = "#FF1463";
          break;
      }

      return color;
    },

    expiryColor(num_days) {
      var color = "grey";
      switch (true) {
        case num_days > 90:
          color = "rgb(18, 37, 58)";
          break;
        case num_days >= 30:
          color = "#C6AD23";
          break;
        case num_days < 0:
          color = "grey";
          break;
        case num_days < 30:
          color = "#DA0550";
          break;
      }

      return color;
    },

    expiryText(num_days) {
      var color = "grey";
      switch (true) {
        case num_days > 90:
          color = "Current";
          break;
        case num_days >= 30:
          color = "Expiring Soon";
          break;
        case num_days < 0:
          color = "Expired";
          break;
        case num_days < 30:
          color = "Expiring Soon";
          break;
      }

      return color;
    },
    getStatusColour(item) {
      if (item.current) {
        return "#b3bc4b";
      } else {
        return "#E0E0E0";
      }
    },

    getLists() {
      getUserLists()
        .then(data => {
          this.setBookmarks(data);
        })
        .catch(error => {
          error;
        });
    },

    disableIcon(url) {
      if (!url) {
        return true;
      } else {
        return false;
      }
    },

    govAvatarColor(gov) {
      var color = "grey";
      switch (gov) {
        case "NSW":
          color = "#41a7d9";
          break;
        case "VIC":
          color = "#0058AC";
          break;
        case "WA":
          color = "#FFD200";
          break;
        case "QLD":
          color = "#A6003D";
          break;
        case "AUS":
          color = "#D8993B";
          break;
        case "FED":
          color = "#D8993B";
          break;
        case "DEFENCE":
          color = "#D8993B";
          break;
        case "SA":
          color = "#ff4081";
          break;
        case "TAS":
          color = "#4eb888";
          break;
        case "ACT":
          color = "#41a7d9";
          break;
        case "NT":
          color = "#FF3647";
          break;
      }

      return color;
    }
  }
};
</script>

<style scoped>
::v-deep .v-expansion-panel-content__wrap {
  padding-left: 0px;
}

.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}

.tender {
  border-left: 5px solid #ff4081 !important;
}
.card-data {
  width: 150px !important;
}

table {
  border-collapse: collapse;
}

th {
  border-bottom: 1px solid #535353;
}

/* .tr:hover {
  transition: opacity 0.4s ease-in-out;
} */

tbody tr:hover {
  background: #535353;

  -webkit-transition: background-color 0.4s; /* For Safari 3.1 to 6.0 */
  transition: background-color 0.4s;
}

.v-select--dense .v-input__control {
  height: 20px !important;
}

.state-line {
  width: 100%;
  text-align: right;
  border-bottom: 1px solid #4eb888;
  line-height: 0.1em;
  margin: 10px 0px 20px;
}
.state-line-span {
  padding: 0 10px;
}

.state {
  margin: 20px;
  overflow: hidden;
  color: gray;
  text-align: right;
  line-height: 1.6em;
}

.state:before {
  display: block;
  float: left;
  margin-top: 0.8em; /* half the line-height */
  border-top: 2px solid silver;
  width: 95%;
  content: "";
}

.category-indent {
  border-left: 3px solid #979797;
  margin-left: 10px;
}

.consult-indent {
  border-left: 3px solid rgb(255, 20, 99);
  margin-left: 10px;
}
</style>
