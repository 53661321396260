<template>
  <v-timeline>
    <v-timeline-item
      v-for="item in timeline"
      :key="item.date"
      :color="getColor(item.event)"
      small
    >
      <template v-slot:opposite>
        {{ formatDate(item.date) }}
      </template>

      <v-card tile>
        <v-card-title class="pl-7 pb-0">
          <span
            :class="`headline font-weight-light`"
            v-text="item.event"
          ></span>
          <div class="flex-grow-1 desktop-nav"></div>
            <span v-if="item.url" >
              <v-tooltip
                top
                dark
                color="#000000"
                style="opacity: 0.7;"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    medium
                    icon
                    
                    light
                    :href="item.url"
                    target="_blank"
                    style="text-decoration: none !important;"
                  >
                    <v-icon>mdi-web</v-icon>
                  </v-btn>
                </template>
                <span>Go to Source</span>
              </v-tooltip>
              </span>
        </v-card-title>
        <v-card-text class="pl-7">
          <div :class="`font-weight-bold`" style="color:#12253a;" v-if="item.event == 'Commenced'">{{ item.value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</div>
          <div :class="`font-weight-bold`" style="color:#12253a;" v-else-if="item.value_change">
            {{ item.value_change.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }} | published {{ item.amendment_published }}</div>
        </v-card-text>
        <v-card-text class="pt-0 pb-0 pl-7" v-if="item.notes || item.reference" >
        <div style="color:#12253a;">
          <span v-if="(item.reference && !item.notes) || item.event == 'Commenced'" style="color:#12253a;">
            <router-link
              target="_blank"
              text
              :to="{ name: 'tender', params: { id: item.id } }"
              >{{ item.reference }} </router-link
            >
          </span>
          <span>
            <router-link
              target="_blank"
              text
              :to="{ name: 'tender', params: { id: item.id } }"
              >{{ item.notes }}</router-link
            >
           
        </span>
      </div>
        <v-card-text v-if="item.benchmarks" class="caption pl-0 pb-0"> 
          This analysis compares the relative value of the <b>first amendment</b> of this contract with the relative value of initial contract amendments in the market along with the relative duration between the start of the contract and the date of the first amendment.
          Understanding these comparisons can provide an indication of the tendency of an entity to enter into the first amendment of a contract earlier than the norm, or for a higher relative value than the norm.
        </v-card-text>

        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col v-if="item.value_change" :cols="numCols(item)">
                <v-card-text v-if="item.value_change" class="pt-0 pb-0 font-weight-normal">Amendment Value Analysis</v-card-text>
                  <v-divider class="mx-3"></v-divider>
                    <v-row class="ma-0 pa-0">
                      <v-col v-if="item.value_change" :cols="numAnalyisCols(item)" class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                          <v-card-subtitle
                                class="font-weight-normal caption text-center pb-0"
                            style="color:#12253a;"
                            >Value</v-card-subtitle
                            >
                            <v-card-subtitle class="py-0 text-center">
                              <v-progress-circular
                                :value="item.value_percentage_change"
                                color="secondary"
                                :size="100"
                                :width="15"
                              >
                                <strong class="font-weight-bold caption">{{ item.value_percentage_change }}%</strong>
                              </v-progress-circular> 
                            </v-card-subtitle>
                            <v-card-subtitle
                                class="font-weight-normal caption text-center py-0"
                            style="color:#12253a;"
                            >{{ formattedCurrency(item.value_change) }}</v-card-subtitle
                            >
                        </v-card>
                      </v-col>
                      <v-col v-if="item.benchmarks" :cols="numAnalyisCols(item)" class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                          <v-card-subtitle
                                class="font-weight-normal caption text-center pb-0"
                            style="color:#12253a;"
                            >Govt Average</v-card-subtitle
                            >
                            <v-card-subtitle class="py-0 text-center">
                              <v-progress-circular
                                v-if="item.benchmarks"
                                :value="item.benchmarks.benchmark_first_amendment_percentage"
                                color="primary"
                                :size="100"
                                :width="15"
                              >
                                <strong class="font-weight-bold caption">{{ item.benchmarks.benchmark_first_amendment_percentage }}%</strong>
                              </v-progress-circular> 
                            </v-card-subtitle>
                            <v-card-subtitle
                                class="font-weight-normal caption text-center py-0"
                            style="color:#12253a;"
                            >{{ formattedCurrency(item.benchmarks.benchmark_first_amendment_actual_value) }}</v-card-subtitle
                            >
                        </v-card>
                      </v-col>
                      <v-col v-if="item.benchmarks" :cols="numAnalyisCols(item)" class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                          <v-card-subtitle
                                class="font-weight-normal caption text-center pb-0"
                            style="color:#12253a;"
                            >Buyer Average</v-card-subtitle
                            >
                            <v-card-subtitle class="py-0 text-center">
                              <v-progress-circular
                                v-if="item.benchmarks"
                                :value="item.benchmarks.publisher.benchmark_first_amendment_percentage"
                                color="primary"
                                :size="100"
                                :width="15"
                              >
                                <strong class="font-weight-bold caption">{{ item.benchmarks.publisher.benchmark_first_amendment_percentage }}%</strong>
                              </v-progress-circular> 
                            </v-card-subtitle>
                            <v-card-subtitle
                                class="font-weight-normal caption text-center py-0"
                            style="color:#12253a;"
                            >{{ formattedCurrency(item.benchmarks.publisher.benchmark_first_amendment_actual_value) }}</v-card-subtitle
                            >
                        </v-card>
                      </v-col>
                      <v-col v-if="item.benchmarks" :cols="numAnalyisCols(item)" class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                          <v-card-subtitle
                                class="font-weight-normal caption text-center pb-0"
                            style="color:#12253a;"
                            >Category Average</v-card-subtitle
                            >
                            <v-card-subtitle class="py-0 text-center">
                              <v-progress-circular
                                v-if="item.benchmarks"
                                :value="item.benchmarks.category.benchmark_first_amendment_percentage"
                                color="primary"
                                :size="100"
                                :width="15"
                              >
                                <strong class="font-weight-bold caption">{{ item.benchmarks.category.benchmark_first_amendment_percentage }}%</strong>
                              </v-progress-circular> 
                            </v-card-subtitle>
                            <v-card-subtitle
                                class="font-weight-normal caption text-center py-0"
                            style="color:#12253a;"
                            >{{ formattedCurrency(item.benchmarks.category.benchmark_first_amendment_actual_value) }}</v-card-subtitle
                            >
                        </v-card>
                      </v-col>
                    </v-row>
              </v-col>

                <v-col v-if="item.percent_complete >= 0" :cols="numCols(item)">
                  <v-card-text v-if="item.percent_complete" class="pt-0 pb-0 font-weight-normal">Timeframe Analysis</v-card-text>
                    <v-divider class="mx-3"></v-divider>
                        <v-row class="ma-0 pa-0">
                          <v-col v-if="item.percent_complete >= 0" :cols="numAnalyisCols(item)" class="ma-0 pa-0">
                            <v-card tile flat class="py-0 my-0">
                              <v-card-subtitle
                                class="font-weight-normal caption text-center pb-0"
                                style="color:#12253a;"
                                >Elapsed Time</v-card-subtitle
                                >
                                <v-card-subtitle class="py-0 text-center">
                                  <v-progress-circular
                                      v-if="item.percent_complete >= 0"
                                      :value="item.percent_complete"
                                      color="secondary"
                                      :size="100"
                                      :width="15"
                                    >
                                      <strong class="font-weight-bold caption">{{ item.percent_complete }}%</strong>
                                    </v-progress-circular>
                                </v-card-subtitle>
                                <v-card-subtitle
                                    class="font-weight-normal caption text-center py-0"
                                    style="color:#12253a;"
                                    >{{ item.days_since_commencement }} days</v-card-subtitle
                                >
                            </v-card>
                          </v-col>
                          <v-col v-if="item.benchmarks" :cols="numAnalyisCols(item)" class="ma-0 pa-0">
                            <v-card tile flat class="py-0 my-0">
                                <v-card-subtitle
                                class="font-weight-normal caption text-center pb-0"
                                style="color:#12253a;"
                                >Govt Average</v-card-subtitle
                                >
                                <v-card-subtitle class="py-0 text-center">
                                  <v-progress-circular
                                    v-if="item.benchmarks"
                                    :value="item.benchmarks.benchmark_percent_complete"
                                    color="primary"
                                    :size="100"
                                    :width="15"
                                  >
                                    <strong class="font-weight-bold caption">{{ item.benchmarks.benchmark_percent_complete }}%</strong>
                                  </v-progress-circular>  
                                </v-card-subtitle>
                                <v-card-subtitle
                                    class="font-weight-normal caption text-center py-0"
                                    style="color:#12253a;"
                                    >{{ item.benchmarks.benchmark_duration_to_first_amendment }} days</v-card-subtitle
                                >
                            </v-card>
                          </v-col>
                          <v-col v-if="item.benchmarks" :cols="numAnalyisCols(item)" class="ma-0 pa-0">
                            <v-card tile flat class="py-0 my-0">
                              <v-card-subtitle
                                class="font-weight-normal caption text-center pb-0"
                                style="color:#12253a;"
                                >Buyer Average</v-card-subtitle
                                >
                                <v-card-subtitle class="py-0 text-center">
                                  <v-progress-circular
                                    v-if="item.benchmarks"
                                    :value="item.benchmarks.publisher.benchmark_percent_complete"
                                    color="primary"
                                    :size="100"
                                    :width="15"
                                  >
                                    <strong class="font-weight-bold caption">{{ item.benchmarks.publisher.benchmark_percent_complete }}%</strong>
                                  </v-progress-circular>  
                                </v-card-subtitle>
                                <v-card-subtitle
                                    class="font-weight-normal caption text-center py-0"
                                    style="color:#12253a;"
                                    >{{ item.benchmarks.publisher.benchmark_avg_contract_duration }} days</v-card-subtitle
                                >
                            </v-card>
                          </v-col>
                          <v-col v-if="item.benchmarks" :cols="numAnalyisCols(item)" class="ma-0 pa-0">
                            <v-card tile flat class="py-0 my-0">
                                <v-card-subtitle
                                class="font-weight-normal caption text-center pb-0"
                                style="color:#12253a;"
                                >Category Average</v-card-subtitle
                                >
                                <v-card-subtitle class="py-0 text-center">
                                  <v-progress-circular
                                    v-if="item.benchmarks"
                                    :value="item.benchmarks.category.benchmark_percent_complete"
                                    color="primary"
                                    :size="100"
                                    :width="15"
                                  >
                                    <strong class="font-weight-bold caption">{{ item.benchmarks.category.benchmark_percent_complete }}%</strong>
                                  </v-progress-circular>  
                                </v-card-subtitle>
                                <v-card-subtitle
                                    class="font-weight-normal caption text-center py-0"
                                    style="color:#12253a;"
                                    >{{ item.benchmarks.category.benchmark_avg_contract_duration }} days</v-card-subtitle
                                >
                            </v-card>
                          </v-col>
                        </v-row>
                </v-col>
            </v-row> 
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
export default {
  props: {
    timeline: []
  },
  data() {
    return {
    
    };
  },

  methods: {
    getColor(event) {
      // Customize this method to return different colors for different event types
      switch (event) {
        case 'Commenced':
          return 'primary';
        case 'Published':
          return 'secondary';
        case 'Amended':
          return 'highlight';
        case 'Expiry':
          return 'primary';
        default:
          return 'grey';
      }
    },

    formattedCurrency: function(amount) {
      ////////console.log("AMOUNT: " + amount);
      if (!isNaN(amount)) {
        return (
          "$" +
          Math.round(amount)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      } //else {
      //return null;
      //}
    },

    numCols(item) {
      if (item.benchmarks) {
        return 12;
      } else {
        return 6;
      }
    },

    numAnalyisCols(item) {
      if (item.benchmarks) {
        return 3;
      } else {
        return 6;
      }
    },
    
    disableIcon(url) {
      if (!url) {
        return true;
      } else {
        return false;
      }
    },
    formatDate(date) {
      // Format date string to a more readable format
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
  },
};
</script>