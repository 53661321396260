
import { getInstance } from "../auth/index.js";
import { serverUrl } from '../auth/auth_config.json';
import axios from "axios";
import { silentURLUpdate } from "./Utils";

export async function getCurrentUserProfile() {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  try {
    const response = await axios.get(process.env.VUE_APP_API_USER, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    //console.log("response" + JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    //console.error(error);
    throw error; // re-throw the error so it can be caught by the caller
  }
}


export async function getSupplier(id) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  try {
    const endpoint = process.env.VUE_APP_API_GET_SUPPLIER + '?id=' + id;
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    //console.log("response" + JSON.stringify(response.data));
    return response.data;
  } catch (error) {

    throw error; // re-throw the error so it can be caught by the caller
  }
}

export async function searchSuppliers(searchText) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  try {
    const endpoint = process.env.VUE_APP_API_SUPPLIER_SEARCH + '?search=' + searchText;
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    //console.log("response" + JSON.stringify(response.data));
    return response.data;
  } catch (error) {

    throw error; // re-throw the error so it can be caught by the caller
  }
}


export async function setUserOrg(org_id) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_USER + '?org_id=' + org_id;
  var headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/x-www-form-urlencoded"
  };

  return (
    axios
      .put(endpoint, {}, {headers: headers})
      .then(response => {
        ////////////////////console.log("UPDATED STORY SUCCESSFULLY\n", response.data);
        return response.data;
      })
      .catch(error => {
        ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
        return error;
      })
  );
  //return true;
}

export async function getSavedSearches() {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  axios
    .get(process.env.VUE_APP_API_SEARCHES, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      ////////////////////console.log("SAVED SEARCHES" + JSON.stringify(response.data.saved_searches));
      return response.data.saved_searches;
    })
    .catch(error => {
      ////////////////////console.log(error);
      return error;
    });
}

export async function getPanelParticipation(son_id, gov_type, entity_type) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_PANEL_PARTICIPATION+'?'+'son_id='+son_id+'&'+'gov_type='+gov_type+'&'+'entity_type='+entity_type;
  
  return axios
    .get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      ////////////////////console.log("SAVED SEARCHES" + JSON.stringify(response.data.saved_searches));
      //////console.log("data: ", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log(error);
      return error;
    });
}

export async function getPanelSuppliers(son_id, gov_type, supplier_status, page) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_PANEL_SUPPLIERS+'?'+'son_id='+son_id+'&'+'gov_type='+gov_type+'&'+'status='+supplier_status+'&'+'page='+page;
  
  return axios
    .get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      ////////////////////console.log("SAVED SEARCHES" + JSON.stringify(response.data.saved_searches));
      //////console.log("data: ", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log(error);
      return error;
    });
}

export async function entityAssessment(id) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_PUB_RISK_ASSESSMENT+'?'+'id='+id;
  
  return axios
    .get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      ////////////////////console.log("SAVED SEARCHES" + JSON.stringify(response.data.saved_searches));
      //////console.log("data: ", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log(error);
      return error;
    });
}

export async function supplierStats(id) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_PUB_SUPPLIER_STATS+'?'+'id='+id;
  
  return axios
    .get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      ////////////////////console.log("SAVED SEARCHES" + JSON.stringify(response.data.saved_searches));
      //////console.log("data: ", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log(error);
      return error;
    });
}

export async function buyerStats(id) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_SUPP_BUYER_STATS+'?'+'id='+id;
  
  return axios
    .get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      ////////////////////console.log("SAVED SEARCHES" + JSON.stringify(response.data.saved_searches));
      //////console.log("data: ", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log(error);
      return error;
    });
}


export async function getPanels(gov_type, page, terms) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_PANELS;

  var query = Object;
  var urlSettings = [];
  if (gov_type) {
    urlSettings[0] = {field_name: "gov_type", value: gov_type}
  };

  urlSettings[1] = {field_name: "page", value: page};

  if (terms && terms.length > 0){
    if (gov_type) {
      query = {gov_type: gov_type, page: page, terms:terms};
    }
    else{
      query = {page: page, terms:terms};
    }

    urlSettings[2]= {field_name: "terms", value: JSON.stringify(terms)}
    /* var settingsIndex = urlSettings.length;
    for (var i = 0; i < terms.length; i++){
      urlSettings[settingsIndex] = {field_name: "terms", value: terms[i]};
      settingsIndex++;
    } */ 
  }
  else{
    if (gov_type) {
      query = {gov_type: gov_type, page: page};
    }
    else{
      query = {page: page};
    }
  }

  silentURLUpdate(urlSettings);
  
  return axios
    .get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {query}
    })
    .then(response => {
      ////////////////////console.log("SAVED SEARCHES" + JSON.stringify(response.data.saved_searches));
      //////console.log("data: ", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log(error);
      return error;
    });
}

export async function getSonEntityPerformance(son_id, entity_type, entity_id) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_PANEL_ENTITY_PERFORMANCE+'?'+'son_id='+son_id+'&'+'entity_type='+entity_type+'&'+'entity_id='+entity_id;
  
  return axios
    .get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      ////////////////////console.log("SAVED SEARCHES" + JSON.stringify(response.data.saved_searches));
      //////console.log("data: ", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log(error);
      return error;
    });
}

export async function getProfileMemberPerformance(profile_id, profile_type, entity_type, entity_id) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_PROFILE_MEMBER_PERFORMANCE+'?'+'profile_id='+profile_id+'&'+'profile_type='+profile_type+'&'+'entity_type='+entity_type+'&'+'entity_id='+entity_id;
  
  return axios
    .get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      ////////////////////console.log("SAVED SEARCHES" + JSON.stringify(response.data.saved_searches));
      //////console.log("data: ", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log(error);
      return error;
    });
}

export async function addCluster(name, entityType) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("in methond@@@@");

  var endpoint = process.env.VUE_APP_API_CLUSTER+'?'+'name='+name+'&'+'entity_type='+entityType;
  //var data = {cluster_ids: clusterIds, entity_type: entityType, entities: entities}

  return axios
    .post(endpoint, {}, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      ////////////////////console.log(response.data);
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function deleteCluster(cluster_id) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("in methond@@@@");

  var endpoint = process.env.VUE_APP_API_CLUSTER + "?id=" + cluster_id;

  var headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/x-www-form-urlencoded"
  };

  return (
    axios
      //.get("http://128.199.248.51/api/1/tenders")
      .delete(endpoint, {
        headers: headers
        //data: {id: list_id}
      })
      .then(response => {
        ////////////////////console.log("CREATED SUCCESSFULLY :", response.data.data);
        return response.data.data;
      })
      .catch(error => {
        ////////////////////console.log("ERROR IN delete list.. ERROR: ", error );
        return error;
      })
  );
}


export async function updateClusters(clusterIds, entityType, entities) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("in methond@@@@");

  var endpoint = process.env.VUE_APP_API_CLUSTER_ENTITIES;
  var data = {cluster_ids: clusterIds, entity_type: entityType, entities: entities}

  return axios
    .post(endpoint, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      ////////////////////console.log(response.data);
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function deleteClusterEntities(clusterId, entity_ids) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("in methond@@@@");

  var headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/x-www-form-urlencoded"
  };

  var endpoint = process.env.VUE_APP_API_CLUSTER_ENTITIES;
  var data = {cluster_id: clusterId, entity_ids: entity_ids}

  return axios
    .delete(endpoint, {headers: headers, data: data})
    .then(response => {
      ////////////////////console.log(response.data);
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function getClusters(entityType) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("in methond@@@@");

  var endpoint = process.env.VUE_APP_API_CLUSTERS+'?'+'entity_type='+entityType;
  return axios
    .get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function getCluster(id) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("in methond@@@@");

  var endpoint = process.env.VUE_APP_API_CLUSTER+'?'+'id='+id;
  return axios
    .get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function fetchStoryboards() {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("in methond@@@@");

  var endpoint = process.env.VUE_APP_API_STORYBOARDS
  return axios
    .get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
  //return true;
}

export async function fetchGovTypes() {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("in methond@@@@");

  var endpoint = process.env.VUE_APP_API_GOV_TYPES
  return axios
    .get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
  //return true;
}

export async function deleteStory(storyId) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_STORY + '/' + storyId
  return axios
    .delete(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      ////////////////////console.log(response.data);
      return response.data;
    })
    .catch(error => {
      return error;
    });

}

export async function addStory(storyboardId, data) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_STORY + '/' + storyboardId
  return axios
    .post(endpoint, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      ////////////////////console.log(response.data);
      return response.data;
    })
    .catch(error => {
      return error;
    });

}

export async function addStoryboard(data) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_STORYBOARD
  return axios
    .post(endpoint, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
  //return true;
}

export async function fetchStoryboard(id) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("in methond@@@@");

  var endpoint = process.env.VUE_APP_API_STORYBOARD + '/' + id

  return axios
    .get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      window.location.replace("/not_found");
      return error;
    });
  //return true;
}

export async function deleteStoryboard(id) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_STORYBOARD + '/' + id
  return axios
    .delete(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      ////////////////////console.log(response.data);
      return response.data;
    })
    .catch(error => {
      return error;
    });

}

export async function fetchOpenboard(id) {

  var endpoint = process.env.VUE_APP_API_OPENBOARD + '?public_id=' + id

  return axios
    .get(endpoint)
    .then(response => {
      
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN fetchStoryboard.. ERROR: ", error );
      //this.$router.push('notfound')
      window.location.replace("/not_found");
      return error;
    });
  //return true;
}

export async function getPanel(son_id, gov_type) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_PANEL + '?son_id=' + son_id + '&gov_type=' + gov_type;
  var headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/x-www-form-urlencoded"
  };

  return (
    axios
      .get(endpoint, {headers: headers})
      .then(response => {
        ////////////////////console.log("UPDATED STORY SUCCESSFULLY\n", response.data);
        return response.data.data;
      })
      .catch(error => {
        ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
        return null;
      })
  );
  //return true;
}

export async function updateStoryboard(storyboard) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var data = storyboard;

  var endpoint = process.env.VUE_APP_API_STORYBOARD + '/' + storyboard.id;
  var headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/x-www-form-urlencoded"
  };

  return (
    axios
      .put(endpoint, data, {headers: headers})
      .then(response => {
        ////////////////////console.log("UPDATED STORY SUCCESSFULLY\n", response.data);
        return response.data;
      })
      .catch(error => {
        ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
        return error;
      })
  );
  //return true;
}

export async function updateStory(story) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var data = story;

  var endpoint = process.env.VUE_APP_API_STORY + '/' + story.id;
  var headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/x-www-form-urlencoded"
  };

  return (
    axios
      //.get("http://128.199.248.51/api/1/tenders")
      .put(endpoint, data, {headers: headers})
      .then(response => {
        ////////////////////console.log("UPDATED STORY SUCCESSFULLY\n", response.data);
        return response.data;
      })
      .catch(error => {
        ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
        return error;
      })
  );
  //return true;
}

export async function executeOpenSearch(query, page) {
  ////////////////////console.log("ROUTE: ", process.env.VUE_APP_API_OPEN_SEARCH);
  //var query = VUE_APP_API_LISTS + "/" + list_id;

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_OPEN_SEARCH + "?query=";
  var endpoint =
    endpoint +
    query +
    "&page=" +
    page;

  return axios
    .get(endpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
    .then(response => {
      //////////////////console.log("FETCHED PROFILE :", response.data.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
}

export async function getParentCategories() {
  ////////////////////console.log("in methond@@@@");

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  return (
    axios
      //.get("http://128.199.248.51/api/1/tenders")
      .get(process.env.VUE_APP_API_PARENT_CATEGORIES, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then(response => {
        ////////////////////console.log("in methond@@@@ :", response.data.data);
        return response.data.data;
      })
      .catch(error => {
        ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
        return error;
      })
  );
  //return true;
}

export async function getSubCategories(parent_codes) {
  ////////////////////console.log("in methond@@@@");

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var query = {parent_codes: parent_codes}

  return (
    axios
      //.get("http://128.199.248.51/api/1/tenders")
      .get(process.env.VUE_APP_API_SUB_CATEGORIES, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: {
          query: query
          //page: page,
        }
      })
      .then(response => {
        ////////////////////console.log("API MGR SUBCATS :", response.data);
        return response.data;
      })
      .catch(error => {
        ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
        return error;
      })
  );
  //return true;
}

export async function getUserLists() {
  ////////////////////console.log("in methond@@@@");

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  return (
    axios
      //.get("http://128.199.248.51/api/1/tenders")
      .get(process.env.VUE_APP_API_LISTS, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then(response => {
        ////////////////////console.log("in methond@@@@ :", response.data.data);
        return response.data.data;
      })
      .catch(error => {
        ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
        return error;
      })
  );
  //return true;
}

export function getListItemsQuery(list) {
  ////////////////////console.log("in methond@@@@");
  //var endpoint = process.env.VUE_APP_API_LISTS + "/" + list_id;
  var query = list.query;

  return query;
}

export async function searchByEntityId(id, entity_type) {
  var params = {};

  var results = [];

  if (entity_type === "publisher") {
    params["query"] = { publisher_id: [id] };
  } else {
    params["query"] = { supplier_id: [id] };
  }

  results = await executeSearch(params);
  return results;
  /* executeSearch(params).then(data => {
    return data
    }); */
}

export async function groupBySearch(query, page, field, sort_by) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  return axios
    .get(process.env.VUE_APP_API_GRP_BY_SEARCH, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        field: field,
        sort_by: sort_by,
        query: query
        //page: page,
      }
    })
    .then(response => {
      ////////////////////console.log("RESULTS :", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
}

export async function executePlainTextSearch(query) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  return axios
    .get(process.env.VUE_APP_API_AI_QUERY, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        query: query,
      }
    })
    .then(response => {
      //console.log("RESULTS :", response);
      return response;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
}

export async function deleteAIQuery(aiquery_id) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  ////////////////////console.log("in methond@@@@");
  var endpoint = process.env.VUE_APP_API_AI_QUERY + "?id=" + aiquery_id;
  var headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/x-www-form-urlencoded"
  };

  return (
    axios
      //.get("http://128.199.248.51/api/1/tenders")
      .delete(endpoint, {
        headers: headers
        //data: {id: list_id}
      })
      .then(response => {
        ////////////////////console.log("CREATED SUCCESSFULLY :", response.data.data);
        return response;
      })
      .catch(error => {
        ////////////////////console.log("ERROR IN delete list.. ERROR: ", error );
        return error;
      })
  );
  //return true;
}

export async function fetchAIQueries() {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  return axios
    .get(process.env.VUE_APP_API_GET_AI_QUERIES, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      ////////////////////console.log("FETCHED PROFILE :", response.data.aggregations);
      return response;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
}


export async function executeSearch(query, page) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  return axios
    .get(process.env.VUE_APP_API_ADV_SEARCH, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        query: query,
        page: page
      }
    })
    .then(response => {
      ////////////////////console.log("RESULTS :", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
}

export async function searchAnalyzer(query) {
  ////////////////////console.log("SEARCH ANALYZER: " + JSON.stringify(query));
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  return axios
    .get(process.env.VUE_APP_API_ANALYZER, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        query: query,
        page: 1
      }
    })
    .then(response => {
      ////////////////////console.log("RESULTS :", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
}

export async function histogramSearch(query, page) {
  ////////////////////console.log("HISTOGRAM: " + JSON.stringify(query));
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  return axios
    .get(process.env.VUE_APP_API_GRP_BY_HISTOGRAM, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        query: query,
        page: page
      }
    })
    .then(response => {
      ////////////////////console.log("RESULTS :", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
}

export async function graphSearch(query, page) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  return axios
    .get(process.env.VUE_APP_API_GRP_BY_GRAPH, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        query: query,
        page: page
      }
    })
    .then(response => {
      ////////////////////console.log("RESULTS :", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
}

export async function entityAnalysis(page, query, entity_type, after) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  return axios
    .get(process.env.VUE_APP_API_ANALYZER_ENTITIES, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        query: query,
        page: page,
        entity_type: entity_type,
        after: after
      }
    })
    .then(response => {
      ////////////////////console.log("RESULTS :", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
}

export async function entitySearch(query, page) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  return axios
    .get(process.env.VUE_APP_API_GRP_BY_ENTITY, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: {
        query: query,
        page: page
      }
    })
    .then(response => {
      ////////////////////console.log("RESULTS :", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
}

export async function getStats() {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  return axios
    .get(process.env.VUE_APP_API_STATS, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      ////////////////////console.log("FETCHED PROFILE :", response.data.aggregations);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
}

export async function fetchBuyerIndustries() {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_PUBLISHER_INDUSTRY_LIST
  return axios
    .get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
}

export async function fetchProcurementMethods() {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_GET_PROCUREMENT_METHODS
  return axios
    .get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
}

export async function fetchCategoryList() {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var endpoint = process.env.VUE_APP_API_GET_CATEGORY_LIST
  return axios
    .get(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
}

export async function fetchNotifications(params) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  return axios
    .get(process.env.VUE_APP_API_NOTIFICATIONS, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: params
    })
    .then(response => {
      ////////////////////console.log("FETCHED DATA :", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
}

export async function removeFromList(tender_id, lists) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var data = {"tender_id": parseInt(tender_id), "lists": lists}

  var endpoint =
    process.env.VUE_APP_API_BOOKMARK;
  var headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/x-www-form-urlencoded"
  };

  return (
    axios
      //.get("http://128.199.248.51/api/1/tenders")
      .post(endpoint, data, { headers })
      .then(response => {
        ////////////////////console.log("ADDED SUCCESSFULLY :", response.data.data);
        return response.data.data;
      })
      .catch(error => {
        ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
        return error;
      })
  );
  //return true;
}

export async function addToList(tender_id, lists) {
  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  var data = Object;

  ////console.log("tender_id: ", tender_id);

  if (Array.isArray(tender_id)){
    //////console.log("tender_id: ", tender_id);
    data = {"tender_id": tender_id, "lists": lists}
  }
  else{
    data = {"tender_id": parseInt(tender_id), "lists": lists}
  }

  //var data = {"tender_id": tender_id, "lists": lists}

  var endpoint =
    process.env.VUE_APP_API_BOOKMARK;
  var headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/x-www-form-urlencoded"
  };

  return (
    axios
      //.get("http://128.199.248.51/api/1/tenders")
      .post(endpoint, data, { headers })
      .then(response => {
        ////////////////////console.log("ADDED SUCCESSFULLY :", response.data.data);
        return response.data.data;
      })
      .catch(error => {
        ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
        return error;
      })
  );
  //return true;
}


export async function createNewList(list_name) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("createNewList");
  var endpoint = process.env.VUE_APP_API_LIST + "?name=" + list_name;
  var headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/x-www-form-urlencoded"
  };

  return (
    axios
      //.get("http://128.199.248.51/api/1/tenders")
      .post(endpoint, null, { headers })
      .then(response => {
        ////////////////////console.log("CREATED SUCCESSFULLY :", response.data.data);
        return response.data.data;
      })
      .catch(error => {
        ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
        return error;
      })
  );
  //return true;
}

export async function updateNewsPage(entity_id, url) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  ////////////////////console.log("in methond@@@@");
  var endpoint =
    process.env.VUE_APP_API_PUBLISHERS +
    "/" +
    entity_id +
    "/news_source" +
    "?news=" +
    url;
  var headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/x-www-form-urlencoded"
  };

  return (
    axios
      //.get("http://128.199.248.51/api/1/tenders")
      .post(endpoint, null, { headers })
      .then(response => {
        ////////////////////console.log("CREATED SUCCESSFULLY :", response.data.data);
        return response.data.data;
      })
      .catch(error => {
        ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
        return error;
      })
  );
  //return true;
}

export async function deleteList(list_id) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  ////////////////////console.log("in methond@@@@");
  var endpoint = process.env.VUE_APP_API_LIST + "?id=" + list_id;
  var headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/x-www-form-urlencoded"
  };

  return (
    axios
      //.get("http://128.199.248.51/api/1/tenders")
      .delete(endpoint, {
        headers: headers
        //data: {id: list_id}
      })
      .then(response => {
        ////////////////////console.log("CREATED SUCCESSFULLY :", response.data.data);
        return response.data.data;
      })
      .catch(error => {
        ////////////////////console.log("ERROR IN delete list.. ERROR: ", error );
        return error;
      })
  );
  //return true;
}

export async function autocomplete(terms, field) {
  ////////////////////console.log("in methond@@@@");

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  var query =
    process.env.VUE_APP_API_AUTOCOMPLETE +
    "?" +
    "terms=" +
    encodeURIComponent(terms) +
    "&field=" +
    field;

  return axios
    .get(query, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      ////////////////////console.log("AUTOCOMPLETE :", response.data.data);
      //response.data.data.unshift({ key: terms });
      return response.data.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
  //return true;
}

export async function fetchNews(entity) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("in methond@@@@");

  var params = { entity: entity, cc: "au", category: "business" };

  return axios
    .get(process.env.VUE_APP_API_NEWS, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: params
    })
    .then(response => {
      ////////////////////console.log("FETCHED NEWS :", response.data.data);
      return response.data.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
  //return true;
}

export async function fetchProfile(entity_id) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("in methond@@@@");
  var period = 1040; // 20 years
  var params = { entity_id: entity_id, period: period };

  return axios
    .get(process.env.VUE_APP_API_PROFILE, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: params
    })
    .then(response => {
      ////////////////////console.log("PROFILE: ", JSON.stringify(response.data.data.profile));
      return response.data.data.profile;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
  //return true;
}

export async function fetchProfileByCounterparty(entity_id, counterparty_id, counterparty_type) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("in methond@@@@");
  var period = 1040; // 20 years
  var params = { entity_id: entity_id,
                period: period,
                counterparty_type: counterparty_type+"_id",
                counterparty_id: counterparty_id };

  return axios
    .get(process.env.VUE_APP_API_PROFILE, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: params
    })
    .then(response => {
      ////////////////////console.log("PROFILE: ", JSON.stringify(response.data.data.profile));
      return response.data.data.profile;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
  //return true;
}

export async function fetchSupplier(entity_id) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("in methond@@@@");
  var period = 1040; // 20 yurs
  var params = { entity_id: entity_id, period: period };

  return axios
    .get(process.env.VUE_APP_API_SUPPLIER, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: params
    })
    .then(response => {
      ////////////////////console.log("SUPPLIER: ", JSON.stringify(response.data.data.profile));
      return response.data.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
  //return true;
}

export async function fetchSupplierByCounterparty(entity_id, counterparty_id, counterparty_type) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("in methond@@@@");
  var period = 1040; // 20 yurs
  var params = { entity_id: entity_id,
                period: period,
                counterparty_type: counterparty_type+"_id",
                counterparty_id: counterparty_id };

  return axios
    .get(process.env.VUE_APP_API_SUPPLIER, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: params
    })
    .then(response => {
      ////////////////////console.log("SUPPLIER: ", JSON.stringify(response.data.data.profile));
      return response.data.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
  //return true;
}

export async function fetchCategory(category_id) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("in methond@@@@");
  var period = 1040; // 20 yurs
  var params = { category_id: category_id, period: period };

  return axios
    .get(process.env.VUE_APP_API_CATEGORY, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: params
    })
    .then(response => {
      ////////////////////console.log("SUPPLIER: ", JSON.stringify(response.data.data.profile));
      return response.data.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
  //return true;
}

export async function fetchPublishers(page_num, query) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  //var query = { period: 156, term: terms, government: government };

  if (query && query["supplier_id"]){
    query.supplier_id = parseInt(query.supplier_id)
  }

  return axios
    .get(process.env.VUE_APP_API_PUBLISHERS, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { query: query, page: page_num }
    })
    .then(response => {
      ////////////////////console.log("FETCHED PUBLISHERS :", response.data.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
  //return true;
}


export async function fetchCategories(page_num, query) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("in methond@@@@");

  if (query && query["supplier_id"]){
    query.supplier_id = parseInt(query.supplier_id)
  }

  //var query = { period: 156, term: terms };

  return axios
    .get(process.env.VUE_APP_API_CATEGORIES, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { query: query, page: page_num }
    })
    .then(response => {
      ////////////////////console.log("FETCHED PUBLISHERS :", response.data.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
      return error;
    });
  //return true;
}

export async function fetchSuppliers(page_num, query) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  if (query && query["publisher_id"]){
    query.publisher_id = parseInt(query.publisher_id)
  }

  if (query && query["category_id"] && Array.isArray(query["category_id"])){
    query.category_id = parseInt(query["category_id"][0])
  }

  let url = process.env.VUE_APP_API_SUPPLIERS;
  //process.env.VUE_APP_API_SUPPLIERS

  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { query: query, page: page_num }
    })
    .then(response => {
      ////////////////////console.log("FETCHED suppliers :", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN FETCH SUPPLIERS.. ERROR: ", error);
      return error;
    });
  //return true;
}

export async function fetchSupplierDirectory(page_num, query) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();

  let url = process.env.VUE_APP_API_SUPPLIER_DIRECTORY;
  //process.env.VUE_APP_API_SUPPLIERS

  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { query: query, page: page_num }
    })
    .then(response => {
      ////////////////////console.log("FETCHED suppliers :", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN FETCH SUPPLIERS.. ERROR: ", error);
      return error;
    });
  //return true;
}

export async function fetchContacts(publisher_id, page) {

  const instance = getInstance();
  const accessToken = await instance.getTokenSilently();
  ////////////////////console.log("in methond@@@@");

  //var query = { publisher_id: publisher_id };

  let url = process.env.VUE_APP_API_PUB_CONTACTS;
  //process.env.VUE_APP_API_SUPPLIERS

  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { publisher_id: publisher_id, page: page }
    })
    .then(response => {
      ////////////////////console.log("FETCHED suppliers :", response.data);
      return response.data;
    })
    .catch(error => {
      ////////////////////console.log("ERROR IN FETCH SUPPLIERS.. ERROR: ", error);
      return error;
    });
  //return true;
}
