<template>
  <v-card class="chart_container pa-2" tile flat>
    <chart-header :title="title"
      :subtitle="subtitle"
      :widget_name="'market-activity'"
      :disable_open_in_new="true"
      :disable_show_data="true"
      :disable_download_data="true"
      :total_result="total_records"
      :total_amount="total_amount"
      :search_query="plain_text_query"
      :chart_settings="chart_settings"
      :embed_object="embed_object"
      :embed_obj_changed="embed_obj_changed"
      @embed="embed_code" />
    
    <v-row class="pt-9">
      <v-col cols="12">
        <search-analysis
          @chart_object="chart_object"
          :embed="embed"
          :id="id"
        />
      </v-col>
    </v-row>
    
  </v-card>
</template>

<script>
import SearchAnalysis from "../components/SearchAnalysis.vue";
import ChartHeader from "../components/ChartHeader";
//import Donut from "@/components/Donut.vue";

export default {
  components: {
    SearchAnalysis,
    ChartHeader,
  },
  props: {
    title: {
      type: String,
      default: "Market Activity"
    },
    description: String,
    query: Object,
    total_records: Number,
    total_amount: Number,
    plain_text_query: String,
    chart_size: {
      type: String(),
      default: "large"
    }
  },

  data() {
    return {
      chart_settings: [],
      subtitle: "Active contracts and year on year trends",
      
      changed: Number,
      download: Number,
      dataview: {headers:[], data:[]},
      embed: Number,
      embed_object: Object,
      embed_obj_changed: Number,
      pub_industry_aggs: [],
      pub_industry_aggs_count: [],
    };
  },

  watch: {
    $route(to, from) {
    },

  },

  created(){
    
  },

  async mounted() {

  },

  computed:{
    id(){
      return (Math.random() + Date.now()) * Math.random();
    },
  },

  methods: {

    chart_object(obj){
      ////////////console.log("OBJECT: ", obj);
      this.embed_object["title"] = this.title;
      this.embed_object["description"] = this.description;
      this.embed_object["chart_type"] = "search_analysis";
      this.embed_object["payload"] = obj
      this.embed_object["id"] = (Math.random() + Date.now()) * Math.random();
      this.embed_obj_changed = (Math.random() + Date.now()) * Math.random();
    },

    embed_code(status){
      this.embed =
          (Math.random() + Date.now()) * Math.random();

    },

  }
};
</script>

<style>
/* .pie-chart-container {
  max-height: 360px !important;
  max-width: 600px !important;
} */
</style>
