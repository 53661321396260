<template>
<span>
  <div :id="id" class="reportTree"></div>
  <chart-legend v-if="story"
    class="pt-3"
    :colors="legend['colors']" 
    :legendData="legend['legendData']"
    :groups="legend['groups']"
    :total_results="legend['total_results']"
    :total_amount="legend['total_amount']"
    :metric="legend['metric']" />
</span>
</template>
<script>
import {downloadFile} from "../components/Utils";
import * as echarts from "echarts";
import ChartLegend from "../components/ChartLegend.vue";

export default {

  components: {
    ChartLegend
    //Donut
  },

  props: {
    legend_settings: null,
    chart_data: [],
    changed: Number,
    download: Number,
    target_group: String,
    embed: Number,
    story: Boolean,
    chart_options: {},
    legend: Object,
    id: Number,
    reset:{
            type: Boolean,
            default: false
        }
  },
  data() {
    return {
      imgPath: null,
      labels: [],
      myChart: Object,
      options: {
        series: {},
        tooltip: {
          //backgroundColor: "rgb(255, 20, 99)",
          //backgroundColor: "#F6F8FC",
          //borderColor: "#12253a",
          borderWidth: 0,
          //borderRadius: 4,
          formatter: function (params){

            function convert (num){

              if (num > 999 && num < 1000000) {
                num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
              } else if (num > 1000000 && num < 1000000000) {
                num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
              } else if (num > 1000000000) {
                num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
              }

              return num;
            }

            function territory_color (name){

              var color = "grey";
              switch (name) {
                case "NSW":
                  color = "#41a7d9";
                  break;
                case "VIC":
                  color = "#0058AC";
                  break;
                case "WA":
                  color = "#FFD200";
                  break;
                case "QLD":
                  color = "#A6003D";
                  break;
                case "AUS":
                  color = "#D8993B";
                  break;
                case "FED":
                  color = "#D8993B";
                  break;
                case "DEF":
                  color = "#d8673b";
                  break;
                case "SA":
                  color = "#ff4081";
                  break;
                case "TAS":
                  color = "#4eb888";
                  break;
                case "ACT":
                  color = "#41a7d9";
                  break;
                case "NT":
                  color = "#FF3647";
                  break;
              }

              return color;
            }


            if (params.value[4] == "amount"){

              var value = params.value[0];
              var territory_value = params.treePathInfo[1].value[0];

              value = convert(value);
              value = "$"+value;
              territory_value = convert(territory_value);
              territory_value = "$"+territory_value;

              //var per = Math.round(params.value[1], 2);
              var per = params.value[1].toFixed(1);

              var num_awards = params.value[2];
              var territory = params.treePathInfo[1].name;
              var color = territory_color(territory);
              var contract_plural = "contract";

              if (num_awards > 1){
                contract_plural = "contracts";
              }

              return [
                //'<div style="background: red; padding:0px;">',

                '<div style="color: #12253a; font-family:Roboto; font-weight:bold; font-size:14;">' + params.name + '</div>',
                '<span style="color: #12253a; font-size:14; font-weight:bold;">' + num_awards + '</span>'+
                  '<span style="color: #12253a; font-family:Roboto; font-size:14;"> '+ contract_plural + ' awarded, worth </span>' +
                  '<span style="color: #12253a; font-size:14; font-weight:bold;">' + value + '</span>'+
                  '<span style="color: #12253a; font-size:14;">.</span><br>',
                
                '<span style="color: #12253a; font-family:Roboto; font-size:14;">This equates to '+
                  '<span style="color: #12253a; font-size:14; font-weight:bold;">' + per + '%</span> of '+
                    '<span style="color:'+color+'; font-weight:bold;">'+ territory +
                    '</span> government awards <br> for the dataset.</span>'
              ].join('');
            }
            else {

              var value = params.value[2];
              value = convert(value);
              value = "$"+value;

              var territory_awards = params.treePathInfo[1].value[0];

              //var per = Math.round(params.value[1], 2);
              var per = params.value[1].toFixed(1);

              var num_awards = params.value[0];
              var territory = params.treePathInfo[1].name;
              var color = territory_color(territory);
              var contract_plural = "contract";

              if (num_awards > 1){
                contract_plural = "contracts";
              }

              return [
                //'<div style="background: red; padding:0px;">',

                '<div style="color: #12253a; font-family:Roboto; font-weight:bold; font-size:14;">' + params.name + '</div>',
                '<span style="color: #12253a; font-size:14; font-weight:bold;">' + num_awards + '</span>'+
                  '<span style="color: #12253a; font-family:Roboto; font-size:14;"> '+ contract_plural + ' awarded, worth </span>' +
                  '<span style="color: #12253a; font-size:14; font-weight:bold;">' + value + '</span>'+
                  '<span style="color: #12253a; font-size:14;">.</span><br>',
                
                '<span style="color: #12253a; font-family:Roboto; font-size:14;">This equates to '+
                  '<span style="color: #12253a; font-size:14; font-weight:bold;">' + per + '%</span> of '+
                    '<span style="color:'+color+'; font-weight:bold;">'+ territory +
                    '</span> government awards <br> for the dataset.</span>'
              ].join('');
            }
            
          }
        }
      }
    };
  },

  watch: {
    embed() {
      ////////////console.log("CALL SHARE CHART\n", this.myChart);
      this.saveChart();
    },

    changed() {
      ////////////console.log("CHANGED-- ", this.chart_data);
      ////////////console.log("ID IN CHART: ", this.id)
      this.loadChart();
    },


    download() {
      ////////////console.log("CHANGED-- ", this.chart_data);
      this.saveImage();
    },

    reset(){
      this.myChart.clear()
    }
  },

  created() {
    /* if (this.chart_data.length > 0) {
      this.loadChart();
    } */
  },

  mounted() {
    //if (this.chart_data.length > 0) {
    this.$nextTick(()=>{
        this.loadChart();
    });
      ////////////console.log("MOUNTED-- ", this.chart_data);
    //}
  },

  computed: {

  },
  
  methods: {

    saveChart(){

      var imgUrl = this.myChart.getDataURL();

      let data = {
        imgData: imgUrl, //this.myChart.getDataURL(),
        options: this.options,
        legend: this.legend_settings,
      };

      ////////////console.log("EMBED CODE: ", data);

      this.$emit("chart_object", data);

    },

    saveImage(){

      var img = new Image();
      
      img.src = this.myChart.getDataURL({
          pixelRatio: 2,
          backgroundColor: '#fff'
      });

      downloadFile(img.src);
      //this.saveChart();

    },

    numFormatter(num) {
      if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
      } else if (num > 1000000 && num < 1000000000) {
        return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
      } else if (num > 1000000000) {
        return (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
      } else if (num < 900) {
        return num; // if value < 1000, nothing to do
      }
    },

    loadChart() {
      //var data = [{name: "Govt Awards", children: this.chart_data}]
      if (this.story){
        this.chart_options.tooltip.formatter = function (params){
              function convert (num){

                if (num > 999 && num < 1000000) {
                  num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                } else if (num > 1000000 && num < 1000000000) {
                  num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                } else if (num > 1000000000) {
                  num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                }

                return num;
              }

              function territory_color (name){

                var color = "grey";
                switch (name) {
                  case "NSW":
                    color = "#41a7d9";
                    break;
                  case "VIC":
                    color = "#0058AC";
                    break;
                  case "WA":
                    color = "#FFD200";
                    break;
                  case "QLD":
                    color = "#A6003D";
                    break;
                  case "AUS":
                    color = "#D8993B";
                    break;
                  case "FED":
                    color = "#D8993B";
                    break;
                  case "DEF":
                    color = "#d8673b";
                    break;
                  case "SA":
                    color = "#ff4081";
                    break;
                  case "TAS":
                    color = "#4eb888";
                    break;
                  case "ACT":
                    color = "#41a7d9";
                    break;
                  case "NT":
                    color = "#FF3647";
                    break;
                }

                return color;
              }


              if (params.value[4] == "amount"){

                var value = params.value[0];
                var territory_value = params.treePathInfo[1].value[0];

                value = convert(value);
                value = "$"+value;
                territory_value = convert(territory_value);
                territory_value = "$"+territory_value;

                //var per = Math.round(params.value[1], 2);
                var per = params.value[1].toFixed(1);

                var num_awards = params.value[2];
                var territory = params.treePathInfo[1].name;
                var color = territory_color(territory);
                var contract_plural = "contract";

                if (num_awards > 1){
                  contract_plural = "contracts";
                }

                return [
                  //'<div style="background: red; padding:0px;">',

                  '<div style="color: #12253a; font-family:Roboto; font-weight:bold; font-size:14;">' + params.name + '</div>',
                  '<span style="color: #12253a; font-size:14; font-weight:bold;">' + num_awards + '</span>'+
                    '<span style="color: #12253a; font-family:Roboto; font-size:14;"> '+ contract_plural + ' awarded, worth </span>' +
                    '<span style="color: #12253a; font-size:14; font-weight:bold;">' + value + '</span>'+
                    '<span style="color: #12253a; font-size:14;">.</span><br>',
                  
                  '<span style="color: #12253a; font-family:Roboto; font-size:14;">This equates to '+
                    '<span style="color: #12253a; font-size:14; font-weight:bold;">' + per + '%</span> of '+
                      '<span style="color:'+color+'; font-weight:bold;">'+ territory +
                      '</span> government awards <br> for the dataset.</span>'
                ].join('');
              }
              else {

                var value = params.value[2];
                value = convert(value);
                value = "$"+value;

                var territory_awards = params.treePathInfo[1].value[0];

                //var per = Math.round(params.value[1], 2);
                var per = params.value[1].toFixed(1);

                var num_awards = params.value[0];
                var territory = params.treePathInfo[1].name;
                var color = territory_color(territory);
                var contract_plural = "contract";

                if (num_awards > 1){
                  contract_plural = "contracts";
                }

                return [
                  //'<div style="background: red; padding:0px;">',

                  '<div style="color: #12253a; font-family:Roboto; font-weight:bold; font-size:14;">' + params.name + '</div>',
                  '<span style="color: #12253a; font-size:14; font-weight:bold;">' + num_awards + '</span>'+
                    '<span style="color: #12253a; font-family:Roboto; font-size:14;"> '+ contract_plural + ' awarded, worth </span>' +
                    '<span style="color: #12253a; font-size:14; font-weight:bold;">' + value + '</span>'+
                    '<span style="color: #12253a; font-size:14;">.</span><br>',
                  
                  '<span style="color: #12253a; font-family:Roboto; font-size:14;">This equates to '+
                    '<span style="color: #12253a; font-size:14; font-weight:bold;">' + per + '%</span> of '+
                      '<span style="color:'+color+'; font-weight:bold;">'+ territory +
                      '</span> government awards <br> for the dataset.</span>'
                ].join('');
              }
              
            };
      }
      
      ////////////console.log("TREE CHART DOM ID: ", this.id);
      var chartDom = document.getElementById(this.id);
      this.myChart = echarts.init(chartDom);

      if (this.story){
          this.chart_options.series.label.normal.formatter = function (params) {

              var num = params.value[0];
              //var per = Math.round(params.value[1], 2);

              var per = params.value[1].toFixed(1);

              if (num > 999 && num < 1000000) {
                num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
              } else if (num > 1000000 && num < 1000000000) {
                num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
              } else if (num > 1000000000) {
                num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
              }

              if (params.value[4] == "amount") num = "$"+num;

                var arr = [
                    '{name|' + params.name + '}',
                    '{hr|}',
                    '{awarded|' + num + '} {label|awarded}'
                ];

                arr.push(
                    '{per|' + per + '%} {label|of territory awards}'
                );

                return arr.join('\n');
            }
          this.myChart.setOption(this.chart_options);
      }
      else
      {
        var dataview = {headers: [], data: []}

        dataview.headers[0] = {text: 'Government', value: 'govt'};
        dataview.headers[1] = {text: 'Govt Volume', value: 'gov_count'};
        dataview.headers[2] = {text: 'Govt Amount ($)', value: 'gov_value'};


        if (this.chart_data[0].children[0].value[4] == "amount"){

          dataview.headers[3] = {text: 'Entity', value: 'entity'};
          dataview.headers[4] = {text: 'Entity Amount ($)', value: 'value'}
          dataview.headers[5] = {text: 'Entity Share of Spend (%)', value: 'spend_share'}
          dataview.headers[6] = {text: 'Entity Volume', value: 'volume'}
          dataview.headers[7] = {text: 'Entity Share of Volume (%)', value: 'volume_share'}

          for (var i in this.chart_data) {

            var govt = this.chart_data[i].name;
            var gov_count = this.chart_data[i].value[1];
            var gov_value = this.chart_data[i].value[0];

            for (var c in this.chart_data[i].children){
              let entity = this.chart_data[i].children[c].name;
              let value = this.chart_data[i].children[c].value[0];
              let spend_share = this.chart_data[i].children[c].value[1];
              let volume = this.chart_data[i].children[c].value[2];
              let volume_share = this.chart_data[i].children[c].value[3];

              dataview.data.push({
                govt: govt,
                gov_count: gov_count,
                gov_value: gov_value,
                entity: entity,
                value: value,
                spend_share: spend_share.toFixed(2),
                volume: volume,
                volume_share: volume_share.toFixed(2)
              });
            }

          }

        }
        else{

          dataview.headers[3] = {text: 'Entity', value: 'entity'};
          dataview.headers[4] = {text: 'Entity Volume', value: 'volume'}
          dataview.headers[5] = {text: 'Entity Share of Volume (%)', value: 'volume_share'}
          dataview.headers[6] = {text: 'Entity Amount ($)', value: 'value'}
          dataview.headers[7] = {text: 'Entity Share of Spend (%)', value: 'spend_share'}


          for (var i in this.chart_data) {

            var govt = this.chart_data[i].name;
            var gov_count = this.chart_data[i].value[0];
            var gov_value = this.chart_data[i].value[1];

            for (var c in this.chart_data[i].children){
              let entity = this.chart_data[i].children[c].name;
              let value = this.chart_data[i].children[c].value[2];
              let spend_share = this.chart_data[i].children[c].value[3];
              let volume = this.chart_data[i].children[c].value[0];
              let volume_share = this.chart_data[i].children[c].value[1];

              dataview.data.push({
                govt: govt,
                gov_count: gov_count,
                gov_value: gov_value,
                entity: entity,
                volume: volume,
                volume_share: volume_share.toFixed(2),
                value: value,
                spend_share: spend_share.toFixed(2)
              });
            }
          }
        }

        let series = {
            type: "treemap",
            name:"Awards",
            width: '100%',
            height: '100%',
            roam: false,
            nodeClick: false,
            breadcrumb: {
              bottom: "0%",
              show: false,
            },
            itemStyle: { normal: { 
                        //borderColor: "#12253a",
                        borderColor: "white",
                        borderWidth: 1
                        }
                      },
            label: {
                  normal: {
                      position: 'insideTopLeft',
                      formatter: function (params) {

                        var num = params.value[0];
                        //var per = Math.round(params.value[1], 2);

                        var per = params.value[1].toFixed(1);

                        if (num > 999 && num < 1000000) {
                          num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                        } else if (num > 1000000 && num < 1000000000) {
                          num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                        } else if (num > 1000000000) {
                          num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                        }

                        if (params.value[4] == "amount") num = "$"+num;

                          var arr = [
                              '{name|' + params.name + '}',
                              '{hr|}',
                              '{awarded|' + num + '} {label|awarded}'
                          ];

                          arr.push(
                              '{per|' + per + '%} {label|of territory awards}'
                          );

                          return arr.join('\n');
                      },
                      rich: {
                          awarded: {
                              fontSize: 22,
                              lineHeight: 30,
                              color: '#fff',
                              fontWeight: '500'
                              //color: 'rgb(255, 231, 100'
                          },
                          per: {
                              fontSize: 14,
                              color: '#fff'
                          },
                          label: {
                              fontSize: 9,
                              backgroundColor: 'rgba(0,0,0,0.3)',
                              color: '#fff',
                              borderRadius: 2,
                              padding: [2, 4],
                              lineHeight: 25,
                              align: 'right'
                          },
                          name: {
                              fontSize: 12,
                              color: '#fff'
                          },
                          hr: {
                              width: '100%',
                              borderColor: 'rgba(255,255,255,0.2)',
                              borderWidth: 0.5,
                              height: 0,
                              lineHeight: 10
                          }
                      }
                  }
              },
            data: this.chart_data
        };
        this.options.series = series;
        this.myChart.setOption(this.options);

        this.$emit('dataview', dataview);

        this.myChart.on('click', param => {

          let args = [];
          args[0] = {field: "government", value: param.treePathInfo[1].name}
          args[1] = {field: this.target_group, value: param.treePathInfo[2].name}

          this.$emit("showTenders", args);
          
        });
      }
    },

    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    }
  }
};
</script>

<style>
.reportTree {
  margin-top: 0px !important;
  padding-top: 0px !important;
  width: 100% !important;
  min-height: 440px !important;
  /* padding: 0px, 0px, 0px, 0px !important; */
}
</style>
